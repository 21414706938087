import React from 'react';
import { SharedError } from '../Shared/SharedError';
require('./error.scss');

export class ContentError extends SharedError {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.state.error) {
            if(typeof window != 'undefined') window.scrollTo(0, 0);
            return (
                <div  className='row'>


                        <div className='col-sm-12 col-sm-12 col-md-12 col-lg-12'>
                            <div className='alert alert-danger' role='alert'>Sorry - There was a problem with your request.</div>
                        </div>
                        <div className='col-sm-12 popularLinks'>
                            <h2>The webpage you were trying to reach was not found on Dillards.com.</h2>
                            <ul>
                                <li>
                                    <a href='/'>Visit the Dillards.com homepage</a>
                                </li>
                                <li>
                                    <a href='/stores'>Find a Dillard's store near you </a>
                                </li>
                                <li>
                                    <a href='/c/DillardsCard'>Access information about your Dillard's Card </a>
                                </li>
                            </ul>
                        </div>


                    {this.renderHiddenError('Content')}
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default ContentError;
