import html2json from 'html2json';

export const wrapQuotes = (input) => {
	if (input.indexOf('"') > -1) {
		return "'" + input + "'";
	} else {
		return '"' + input + '"';
	}
};
const json2html = (json) => {
	// Empty Elements - HTML 4.01
	var empty = ['area', 'base', 'basefont', 'br', 'col', 'frame', 'hr', 'img', 'input', 'isindex', 'link', 'meta', 'param', 'embed'];
	var thisCache = this;
	var child = '';
	if (json.child) {
		child = json.child
			.map(function(c) {
				return thisCache.json2html(c);
			})
			.join('');
	}

	var attr = '';
	if (json.attr) {
		attr = Object.keys(json.attr)
			.map(function(key) {
				var value = json.attr[key];
				if (Array.isArray(value)) value = value.join(' ');
				return key + '=' + thisCache.wrapQuotes(value);
			})
			.join(' ');
		if (attr !== '') attr = ' ' + attr;
	}

	if (json.node === 'element') {
		var tag = json.tag;
		if (empty.indexOf(tag) > -1) {
			// empty element
			return '<' + json.tag + attr + '/>';
		}

		// non empty element
		var open = '<' + json.tag + attr + '>';
		var close = '</' + json.tag + '>';
		return open + child + close;
	}

	if (json.node === 'text') {
		return json.text;
	}

	if (json.node === 'comment') {
		return '<!--' + json.text + '-->';
	}

	if (json.node === 'root') {
		return child;
	}
};
export const htmlUnescape = (str) => {
	try {
		if (str && str.replace) {
			if ((str.indexOf('<') > -1 && str.indexOf('>') > -1) || (str.indexOf('&lt;') > -1 && str.indexOf('&gt;') > -1)) {
				var intermediate =
					'<div>' +
					str
						.replace(/&lt;/g, '<')
						.replace(/&gt;/g, '>')
						.split('#double;')
						.join('"')
						.split('#back;')
						.join('\\')
						.split('--!>')
						.join('-->') +
					'</div>';
				if (intermediate.split('<!--').length !== intermediate.split('-->').length) {
					intermediate = '<div></div>';
				}

				var intermediateJSON = html2json.html2json(intermediate);
				var intermediateReturn = this.json2html(intermediateJSON)
					.split('´')
					.join("'");

				if (intermediateReturn.indexOf('category-container') !== -1 && intermediateReturn.indexOf('col-xs-12 col-sm-6 col-md-6 col-lg-3') !== -1) {
					var intermediateReturn = intermediateReturn.replace('<div><div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">', '');
					intermediateReturn = intermediateReturn.replace('</a></div></div>', '</a>');

					return intermediateReturn;
				} else {
					//remove wrapping divs as they should not be output in the dom
					return intermediateReturn.substring(5, intermediateReturn.length - 6);
				}
			} else {
				return str
					.replace(/&gt;/g, '>')
					.split('#double;')
					.join('"')
					.split('#back;')
					.join('\\')
					.split('´')
					.join("'")
					.split('--!>')
					.join('-->');
			}
		} else {
			return '';
		}
	} catch (e) {
		if (str && str.replace) {
			return str
				.replace(/&lt;/g, '<')
				.replace(/&gt;/g, '>')
				.split('#double;')
				.join('"')
				.split('#back;')
				.join('\\')
				.split('´')
				.join("'")
				.split('--!>')
				.join('-->');
		} else {
			return '';
		}
	}
};
