import React, { Component } from 'react';
import axios from 'axios';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { nodePaths, oneDataCall, pathConfig, fallbackHeaderFooterJSON, facetCovering } from '../../../../../config/globalVariables';
import { pathToRegexp } from 'path-to-regexp';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import { showTarget, rerunAdobe } from '../../../../util/adobeTarget';

Modal.defaultStyles.overlay.backgroundColor = 'transparent';

export class SharedApp extends Component {
	constructor (props) {
		super(props);
		this.state = this.props.initialState ||
			JSON.parse(JSON.stringify(window.__INITIAL_STATE__)) || {
			navState: typeof window != 'undefined' ? (
				window.location.pathname.startsWith('//') ? 
				window.location.pathname.substring(1) + window.location.search + window.location.hash 
				: window.location.pathname + window.location.search + window.location.hash) : '',
			headerFooterData: {},
			contentData: {},
		};

		this.state.country = 'US';
		this.state.currency = 'USD';
		this.state.myDillardsInfo = {};
		this.state.myDillardsInfo.miniCartInfo = {
			cartTotal: 0,
			cartItemCount: 0.0,
		};
		this.state.myDillardsInfo.interestItemInfo = {};
		this.state.myDillardsInfo.orderHistoryInfo = {};
		this.state.myDillardsInfo.userInformation = {
			name: '',
			userId: '-1002',
			DefaultRegistry: '',
			logonId: '',
		};

		this.state.navigationToggles = {
			openFacet: false,
		};

		this.state.splitChunkBinds = {};

		this.state.contentSwitchPath = this.determineSwitchPath();
		this.state.dataLayer = {};
		this.state.recentlyViewedProductsSinceRefresh = [];

		this.state.supers = ['women', 'juniors', 'shoes', 'handbags', 'accessories', 'lingerie', 'beauty', 'men', 'kids', 'home'];
		this.state.superIds = ['410', '430', '425', '470', '435', '480', '420', '445', '440', '450'];
		this.state.updatedFromCookies = false;

		this.state.lastKnownCartTimeState = '';

		this.determineSwitchPath = this.determineSwitchPath.bind(this);
		this.setModalInfo = this.setModalInfo.bind(this);
		this.updateStateWithCookies = this.updateStateWithCookies.bind(this);
		this.setState = this.setState.bind(this);
		this.getURLParamValue = this.getURLParamValue.bind(this);
		this.closeQuickView = this.closeQuickView.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.unBindFrozenRoot = this.unBindFrozenRoot.bind(this);
		this.toggleTransition = this.toggleTransition.bind(this);
		this.setDataLayerInfo = this.setDataLayerInfo.bind(this);
		this.resetDataLayerInfo = this.resetDataLayerInfo.bind(this);
		this.generatePersonalizationParam = this.generatePersonalizationParam.bind(this);
		this.getCurrentPersonalizationParam = this.getCurrentPersonalizationParam.bind(this);
		this.getSuperFromUrl = this.getSuperFromUrl.bind(this);
		this.seoColorInfo = this.seoColorInfo.bind(this);
		this.setNavigationToggle = this.setNavigationToggle.bind(this);
		this.setWindowPosition = this.setWindowPosition.bind(this);
		this.keepHorzFacetOpen = this.keepHorzFacetOpen.bind(this);
		this.updateUrlWithParams = this.updateUrlWithParams.bind(this);
		this.isInternational = this.isInternational.bind(this);
		this.lastKnownCartTimeFunc = this.lastKnownCartTimeFunc.bind(this);
		this.preloadRequiredChunks = this.preloadRequiredChunks.bind(this);
		this.bindPreloadRequiredChunk = this.bindPreloadRequiredChunk.bind(this);
		this.putRecentlyViewedIntoLocalStorage = this.putRecentlyViewedIntoLocalStorage.bind(this);
		this.bambuserCheck = this.bambuserCheck.bind(this);
		this.toggleFooterLink = this.toggleFooterLink.bind(this);
		this.triggerCustomPageView = this.triggerCustomPageView.bind(this);
	}
	determineSwitchPath () {
		//This function is used to determing what content component to render.
		//Will be stored in state at the app level.
		var keys = [],
			chosenNodePathIndex,
			resolvedPathVariables;

		for (var i = 0; i < nodePaths.length; i++) {
			if (!resolvedPathVariables) {
				keys = [];
				var currentNodePathToTest = nodePaths[i];
				var re = pathToRegexp(currentNodePathToTest, keys);
				resolvedPathVariables = re.exec(this.state.navState.split('#')[0].split('?')[0]);
				if (resolvedPathVariables) chosenNodePathIndex = i;
			} else {
				break;
			}
		}
		var chosenPathKey = nodePaths[chosenNodePathIndex];
		return chosenPathKey;
	}
	setModalInfo (newModalInfo) {
		if ($('.mobilePage').length > 0 && $('.ReactModal__KeepOpenFor_OSS').length > 0) {
			//Mobile OSS was scrolling back to middle of the modal
			$('.ReactModal__KeepOpenFor_OSS').scrollTop(0);
		}
		//function is passed down so other components can send data up for modals at the app level.
		this.setState({ modalInfo: newModalInfo });

		// set window scroll position in local storage (fix for modal return to position), set viewport height on html element (for iOS 15 viewport height bug)
		if (this.state.siteFormat == 'mobile') {
			document.querySelector('html').style.height = '100vh';
			if (window.Modernizr && Modernizr.sessionstorage && sessionStorage.getItem('modalPos') == null) {
				let pos = $(document).scrollTop();
				sessionStorage.setItem('modalPos', pos);
			} else {
				if (!window.Modernizr && !Modernizr.sessionstorage && Cookies.get('modalPos') == null) {
					let pos = $(document).scrollTop();
					Cookies.set('modalPos', pos);
				}
	}
		}
	}
	getURLParamValue (key) {
		//It is what it is.
		var URLobject = {};
		var currentLocationSearch = '';
		var urlToUse = typeof window == 'undefined' ? this.state.navState : String(window.location);
		urlToUse = urlToUse.split('#')[0];
		if (urlToUse.indexOf('?') > -1) {
			currentLocationSearch = urlToUse.split('?')[1];
		}

		var URLarray = currentLocationSearch.split('&'),
			arrayLength = URLarray.length;

		for (var x = 0; x < arrayLength; x++) {
			var currentPair = URLarray[x].split('=');

			if (currentPair[0] === 'df')
				currentPair[1] = unescape(currentPair[1])
					.replace(/&/g, '&amp;')
					.replace(/</g, '&lt;')
					.replace(/>/g, '&gt;')
					.replace(/"/g, '&quot;')
					.replace(/'/g, '&apos;')
					.replace("'", '&#x27')
					.replace('%22', '&quot')
					.replace('"', '&quote;');

			if (currentPair[0] === 'facet') {
				currentPair[1] = currentPair[1].split('%7C').join('|');
			}
			URLobject[currentPair[0]] = currentPair[1];
		}
		return URLobject[key] !== undefined ? URLobject[key] : '';
	}
	getCookie (cookieName) {
		//This is also what it is.
		var t = document.cookie.match(cookieName + '=(.*?)(;|$)');
		return t ? unescape(t[1]) : undefined;
	}
	getUserInformation () {
		var userInfoValue = this.getCookie('userInfo');
		try {
			var parseUserInfoCookie = JSON.parse(userInfoValue);
			parseUserInfoCookie.persistent = !this.getCookie(`WC_USERACTIVITY_${parseUserInfoCookie.userId}`);
			return parseUserInfoCookie;
		} catch (e) {
			return { name: '', userId: '-1002', DefaultRegistry: '', logonId: '' };
		}
	}
	componentWillReceiveProps (nextProps) {
		if (
			this.state.quickViewData &&
			window.location.pathname.indexOf(this.state.quickViewData.contentData.productId) > -1 &&
			(nextProps.history.action == 'PUSH' || nextProps.history.action == 'REPLACE')
		) {
			this.state.contentSwitchPath = this.determineSwitchPath();
			this.state.quickViewData.contentData.productLayout = this.state.quickViewData.contentData.oldProductLayout;
			this.setState({
				contentData: JSON.parse(JSON.stringify(this.state.quickViewData.contentData)),
				quickViewData: undefined,
			});
			this.state.scrollToTopAfterRender = true;
		} else if (this.state.navState.split('#')[0] != window.location.pathname + window.location.search) {
			var linkToStateForNextPage = nextProps.location.state;
			if (nextProps.location.state && nextProps.location.state.useOldCategoryId && this.state.dataLayer.page) {
				linkToStateForNextPage.categoryId = this.state.dataLayer.page.categoryId;
			}
			this.state.navState = window.location.pathname.startsWith('//') ? window.location.pathname.substring(1) + window.location.search + window.location.hash : window.location.pathname + window.location.search + window.location.hash;
			if (
				this.determineSwitchPath().indexOf('/p/') > -1 &&
				(nextProps.history.action == 'PUSH' || nextProps.history.action == 'REPLACE') &&
				nextProps.location.state &&
				nextProps.location.state.qv
			) {
				var extraQVProps = nextProps.location.state.extraQVProps || {};
				this.state.quickViewData = {
					headerFooterData: {},
					contentData: {},
				};
				this.setState({ quickViewData: this.state.quickViewData, modalInfo: false, linkToState: linkToStateForNextPage });
				this.fetchDataAsyncQuickView(extraQVProps);
			} else {
				var wasQuickView = this.state.quickViewData ? true : false;
				if (wasQuickView && window.location.pathname.indexOf('/p/') == -1 && nextProps.history.action == 'PUSH') {
					//if we need to nav to a different pagetype from a modal
					this.setState({ quickViewData: undefined, linkToState: linkToStateForNextPage });
					this.fetchDataAsync(nextProps.history.action != 'POP');
				} else if (wasQuickView && this.determineSwitchPath().indexOf('/p/') > -1) {
					//if we need to nave to the product in the modal
					this.state.contentSwitchPath = this.determineSwitchPath();
					this.state.quickViewData = undefined;
					this.setState({ quickViewData: undefined });
				} else {
					if (wasQuickView) {
						this.setState({ quickViewData: undefined, linkToState: linkToStateForNextPage });
					} else {
						this.setState({ linkToState: linkToStateForNextPage });
					}
					if (!wasQuickView && !this.personalizeUrlIfNeeded()) {
						this.fetchDataAsync(nextProps.history.action != 'POP');
					}
				}
			}
		} else {
			if (
				(nextProps.location.pathname == this.state.navState ||
					nextProps.location.pathname == this.state.navState.split('?facet=dil_shipinternational:Y').join('')) &&
				this.state.handleMenuOpen
			)
				this.state.handleMenuOpen = false;
			//dont want to overlay background navState stuff with quickview stuff since the zoom now adds hash params...
			if (!this.state.quickViewData) {
				this.state.navState = window.location.pathname.startsWith('//') ? window.location.pathname.substring(1) + window.location.search + window.location.hash : window.location.pathname + window.location.search + window.location.hash;
				this.state.contentSwitchPath = this.determineSwitchPath();
			}
		}
		if (
			this.determineSwitchPath().indexOf('/p/') > -1 &&
			nextProps.location &&
			nextProps.location.state &&
			nextProps.location.state.fromQv &&
			nextProps.location.state.fromQv == true
		) {
			this.state.fromQv = true;
			// remove window scroll position when going from QV to product page or using nav history
			if (this.state.siteFormat == 'mobile') {
				if (window.Modernizr && Modernizr.sessionstorage && sessionStorage.getItem('qvModalPos')) {
					sessionStorage.removeItem('qvModalPos');
				} else if (Cookies.get('qvModalPos')) {
					Cookies.remove('qvModalPos');
				}
			}
		} else {
			this.state.fromQv = false;
			// set window scroll position in local storage (fix for modal return to position), set viewport height on html element (for iOS 15 viewport height bug)
			if (this.state.siteFormat == 'mobile' && this.state.quickViewData && document.querySelector('.QvModal__Content') == null) {
				document.querySelector('html').style.height = '100vh';
				if (window.Modernizr && Modernizr.sessionstorage && sessionStorage.getItem('qvModalPos') == null) {
					let pos = $(document).scrollTop();
					sessionStorage.setItem('qvModalPos', pos);
				} else {
					if (!window.Modernizr && !Modernizr.sessionstorage && Cookies.get('qvModalPos') == null) {
						let pos = $(document).scrollTop();
						Cookies.set('qvModalPos', pos);
					}
				}
			}
		}

		// add preload link for main image on product pages
		var linkToRemove = document.querySelectorAll('.preloadLink');
		if (this.state.navState.indexOf('/p/') > -1 && (this.state.navState.indexOf('/p/chanel') == -1 && this.state.navState.indexOf('/outfit') == -1) && this.state.quickViewData == undefined && this.props.location.state == undefined) {
			if (linkToRemove.length == 0) {
				if (nextProps.location.state != undefined) {
					var preloadLink = document.createElement('link');
					var productNameForLink = nextProps.location.pathname.split('/p/')[1].split('/')[0];
					var imageNameForLink = nextProps.location.state.di;
					preloadLink.rel = 'preload';
					preloadLink.href = '//dimg.dillards.com/is/image/DillardsZoom/mainProduct/' + productNameForLink + '/' + imageNameForLink + '.jpg';
					preloadLink.as = 'image';
					preloadLink.className = 'preloadLink';
					document.head.appendChild(preloadLink);
				}
			}
		} else {
			if (this.props.location.pathname != nextProps.location.pathname) {
				if (linkToRemove.length > 0) {
					linkToRemove.forEach(function (preloadLink) {
						preloadLink.remove();
					});
				}
			}
		}

		this.preloadRequiredChunks();
		this.toggleFooterLink();
	}

	preloadRequiredChunks (promisesMode) {
		//attempt to load the split chunks before we make it to the next page.
		//  Multiple preloads of the same chunk do not make extra requests.  This is built into the loadable library.
		//  The split chunks should be listed in this.state.splitChunkBinds
		//    The key of the object is a pipe-delimited list of paths that this should be loaded on
		//    the value of the object is the loadable object on which we will attempt to call .preload();
		//  A special case is made for ecard due to it not being route based, but instead a specific product page.
		//    This assumes the catentry stays the same.
		var returnArrayOfPromises = [];
		try {
			var destinationPath = this.determineSwitchPath();
			var destinationUrl = this.state.navState;
			var keysToIterate = Object.keys(this.state.splitChunkBinds);
			for (var iChunk = 0; iChunk < keysToIterate.length; iChunk++) {
				var thisChunkKey = keysToIterate[iChunk];
				var thisChunkValue = this.state.splitChunkBinds[thisChunkKey];
				if (thisChunkKey == destinationPath || thisChunkKey.split('|').indexOf(destinationPath) > -1) {
					for (var iChunkComponents = 0; iChunkComponents < thisChunkValue.length; iChunkComponents++) {
						if (promisesMode) {
							returnArrayOfPromises.push(thisChunkValue[iChunkComponents].load());
						} else {
							thisChunkValue[iChunkComponents].preload();
						}
					}
				}
				if (thisChunkKey == 'ecard' && destinationUrl.indexOf('503516586') > -1) {
					for (var iChunkComponents = 0; iChunkComponents < thisChunkValue.length; iChunkComponents++) {
						if (promisesMode) {
							returnArrayOfPromises.push(thisChunkValue[iChunkComponents].load());
						} else {
							thisChunkValue[iChunkComponents].preload();
						}
					}
				}
			}
		} catch (e) {
			//worst comes to worst, just load chunks when changed already
			console.log(e);
		}
		return returnArrayOfPromises;
	}
	bindPreloadRequiredChunk (chunkKey, chunkToLoad) {
		this.state.splitChunkBinds[chunkKey] = this.state.splitChunkBinds[chunkKey] || [];
		this.state.splitChunkBinds[chunkKey].push(chunkToLoad);
	}
	getSuperFromUrl (passedUrl) {
		var returnSuper = false;
		try {
			var urlToUse = passedUrl || String(window.location);
			for (var i = 0; i < this.state.supers.length; i++) {
				var thisSuper = this.state.supers[i];
				if (
					urlToUse.indexOf(`/${thisSuper}-`) > -1 ||
					urlToUse.indexOf(`/${thisSuper}/`) > -1 ||
					(urlToUse.indexOf(`/${thisSuper}`) > -1 &&
						(urlToUse.indexOf('/sale-clearance/') > -1 ||
							urlToUse.indexOf('/limited-availability/') > -1 ||
							urlToUse.indexOf('/clearance-') > -1 ||
							urlToUse.indexOf('forceFlatResults') > -1 ||
							urlToUse.indexOf('facet') > -1 ||
							thisSuper == 'handbags'))
				) {
					returnSuper = this.state.superIds[i];
				}
			}
		} catch (e) { }
		return returnSuper;
	}
	personalizeUrlIfNeeded () {
		var didPersonalize = false;
		var didInternationalize = false;
		var didSeoColor = false;
		var didAddSort = false;
		var facetIndex = 0;
		var currentUrl = String(window.location).split('#')[0];
		var urlToGoTo = currentUrl;
		var params = [];
		//to handle for stuff like
		// http://localhost/c/women-dresses-cocktail#orderBy=1&beginIndex=0&emphasize=05528671&cm_mmc=social-_-ecom+Facebook-_-111018-_-womenscocktailandpartydresses
		try {
			if (
				String(window.location).indexOf('#') > -1 &&
				currentUrl.indexOf('faqs-notices-policies') == -1 &&
				currentUrl.indexOf('/c/catalogs') == -1 &&
				currentUrl.indexOf('/c/guides-lookbooks') == -1
			) {
				var originalLinkToGoTo = String(window.location);
				var linkToGoTo = originalLinkToGoTo;
				linkToGoTo = originalLinkToGoTo.split('#')[0];
				//other links
				var hashes = originalLinkToGoTo.split('#')[1].split('&');
				for (var i = 0; i < hashes.length; i++) {
					var thisHash = hashes[i];
					if (thisHash.indexOf('emphasize=') == 0) {
						if (linkToGoTo.indexOf('?') > -1) {
							linkToGoTo += '&';
						} else {
							linkToGoTo += '?';
						}
						linkToGoTo += thisHash;
					}
					if (thisHash.indexOf('cm_mmc=') == 0) {
						if (linkToGoTo.indexOf('?') > -1) {
							linkToGoTo += '&';
						} else {
							linkToGoTo += '?';
						}
						linkToGoTo += thisHash;
					}
					//new arrivals
					if (thisHash.indexOf('facet=') == 0) {
						var theFacetsSplit = thisHash
							.split('facet=')
							.join('')
							.split('%7C')
							.join('|')
							.split('|');
						var theFacetsOut = [];
						var facetMap = facetCovering;
						//see if we have any facets to move across
						for (var j = 0; j < facetCovering.facetKeys.length; j++) {
							var thisFacetArray = facetCovering[facetCovering.facetKeys[j]];
							for (var g = 0; g < thisFacetArray.length; g++) {
								var thisFacetFromArray = thisFacetArray[g];
								for (var t = 0; t < theFacetsSplit.length; t++) {
									if (theFacetsSplit[t] == thisFacetFromArray[0]) {
										theFacetsOut.push(`${facetCovering.facetKeys[j]}%3A%22${thisFacetFromArray[1]}%22`);
									}
								}
							}
						}
						//if we have facets to move across, add em
						if (theFacetsOut.length > 0) {
							if (linkToGoTo.indexOf('?') > -1) {
								linkToGoTo += '&';
							} else {
								linkToGoTo += '?';
							}
							theFacetsOut.sort();
							linkToGoTo += 'facet=' + theFacetsOut.join('|');
						}
					}
				}
				if (linkToGoTo.indexOf('?') > -1) {
					if (currentUrl != linkToGoTo) {
						currentUrl = linkToGoTo;
						urlToGoTo = linkToGoTo;
						didSeoColor = true;
					}
				}
			}
		} catch (e) {
			// console.log(e);
		}
		if (currentUrl.indexOf('?') > -1) {
			params = currentUrl
				.split('?')
				.slice(1)
				.join('?')
				.split('&');
			if (params.length == 1 && params[0].length == 0) {
				params = [];
			}
		}
		//sortAdded sort if needed
		if (
			currentUrl.indexOf('/chanel/') == -1 &&
			currentUrl.indexOf('emphasize') == -1 &&
			!this.getURLParamValue('orderBy') &&
			currentUrl.indexOf('outfits') == -1
		) {
			try {
				//this section only matters if you have a sort stored besides popular/JFY
				var sortOptionsToStore = ['1', '3', '4', '5', '6', '7', '8', '9'];
				if (sortOptionsToStore.indexOf(this.state.headerFooterData.JSON.wasParms.defaultNavSort) > -1) {
					sortOptionsToStore.splice(sortOptionsToStore.indexOf(this.state.headerFooterData.JSON.wasParms.defaultNavSort), 1); //remove the default sort option from the list
				}
				if (
					window.Modernizr &&
					Modernizr.sessionstorage &&
					sessionStorage.getItem('storedSortValue') &&
					sortOptionsToStore.indexOf(sessionStorage.getItem('storedSortValue')) > -1
				) {
					//we want to try to predict if it is a page with a result list...
					//all search term urls
					//all /brand urls
					//all /c/ with a facet or forceFlatResults or seocolor
					//all sale clearance, limited availability, and clearance-65 pages with category
					var containsCSuper = false;
					var containsSaleSuper = false;
					var containsLimitedAvailSuper = false;
					var containsSearchTerm = false;
					var containsBrand = false;

					if (
						currentUrl.indexOf('/c/') > -1 ||
						currentUrl.indexOf('/sale-clearance/') > -1 ||
						currentUrl.indexOf('/limited-availability/') > -1 ||
						currentUrl.indexOf('/clearance-') > -1
					) {
						for (var iSuper = 0; iSuper < this.state.supers.length; iSuper++) {
							var thisSuper = this.state.supers[iSuper];
							if (currentUrl.indexOf(`/${thisSuper}`) > -1) {
								//only need to do any checks if it exists in url at all
								//need to check if /c/ url where there will likely be a result list
								if (
									currentUrl.indexOf(`/c/${thisSuper}/`) > -1 || //seocolor
									currentUrl.indexOf(`/c/${thisSuper}-`) > -1 || //subcat
									currentUrl.indexOf(`forceFlatResults`) > -1 || //forceFlat
									currentUrl.indexOf(`facet=`) > -1 //has facet chosen
								) {
									containsCSuper = true;
								}

								//need to check if under sale of clearance
								if (
									currentUrl.indexOf(`/c/sale-clearance/${thisSuper}`) > -1 ||
									currentUrl.indexOf(`/c/clearance-65/${thisSuper}`) > -1 ||
									(currentUrl.indexOf(`/c/clearance-`) > -1 && currentUrl.indexOf(`/${thisSuper}`))
								) {
									containsSaleSuper = true;
								}
								//need to check if under limited avail
								if (currentUrl.indexOf(`/c/limited-availability/${thisSuper}`) > -1) {
									containsLimitedAvailSuper = true;
								}
							}
						}
					}

					//hard coded cause this is the only super currently showing result list.
					if (currentUrl.indexOf('/c/handbags') > -1) {
						containsCSuper = true;
					}

					if (currentUrl.indexOf('/search-term/') > -1) {
						containsSearchTerm = true;
					}
					if (currentUrl.indexOf('/brand/') > -1) {
						containsBrand = true;
					}
					if (containsCSuper || containsSaleSuper || containsLimitedAvailSuper || containsSearchTerm || containsBrand) {
						var sortValueToUse = sessionStorage.getItem('storedSortValue') + '';
						if (containsSaleSuper) {
							//if we are in a sale super, we need to use sale sorts.
							// 3 > 7
							// 4 > 8
							if (sortValueToUse == '3') {
								sortValueToUse = '7';
							}
							if (sortValueToUse == '4') {
								sortValueToUse = '8';
							}
							if (sortValueToUse == '9') {
								sortValueToUse = false;
							}
						} else {
							//if we are in a non-saleSuper we need to use non-sale sorts.
							// 7 > 3
							// 8 > 4
							if (sortValueToUse == '7') {
								sortValueToUse = '3';
							}
							if (sortValueToUse == '8') {
								sortValueToUse = '4';
							}
						}
						if (sortValueToUse) {
							params.push('orderBy=' + sortValueToUse);
							didAddSort = true;
						}
					}
				}
			} catch (e) {
				//stored sort error, lets not fret
				// console.log(e);
			}
		}
		//end sortAdded
		//personalize if needed
		if (
			this.state.headerFooterData.JSON.wasParms.isPersonalizationOn == 'true' &&
			currentUrl.indexOf('/chanel/') == -1 &&
			currentUrl.indexOf('emphasize') == -1 &&
			currentUrl.indexOf('/c/') > -1 &&
			!this.getURLParamValue('orderBy') &&
			currentUrl.indexOf('outfits') == -1 &&
			!didAddSort
		) {
			try {
				var superFromUrl = this.getSuperFromUrl();
				if (
					window.Modernizr &&
					Modernizr.localstorage &&
					Modernizr.sessionstorage &&
					superFromUrl &&
					currentUrl.indexOf('/outfits') == -1 &&
					currentUrl.indexOf('/search-term') == -1
				) {
					var superCatName = this.state.supers[this.state.superIds.indexOf(superFromUrl)];
					if (
						currentUrl.indexOf(superCatName + '-') > -1 ||
						(currentUrl.indexOf(superCatName + '/') > -1 && currentUrl.indexOf('shopbybrand') == -1)||
						currentUrl.indexOf('/sale-clearance/') > -1 ||
						currentUrl.indexOf('/limited-availability/') > -1 ||
						currentUrl.indexOf('/clearance-') > -1 ||
						currentUrl.indexOf('forceFlatResults') > -1 ||
						currentUrl.indexOf('facet') > -1 ||
						superCatName == 'handbags'
					) {
						//a path we want to touch.  this ignores super level except when under sale/clearance/limited
						var personalizationToUse = false;
						var currentPersonalization = this.getCurrentPersonalizationParam(superFromUrl);
						//if it is a string of length 0, they want it not personalized.  move along
						if (typeof currentPersonalization == 'string' && currentPersonalization.length == 0) {
							personalizationToUse = '';
						} else if (typeof currentPersonalization == 'string' && currentPersonalization.length > 0) {
							personalizationToUse = currentPersonalization;
						} else {
							//they had nothing existing, we should try to generate
							var generatedPersonalization = this.generatePersonalizationParam(superFromUrl);
							if (generatedPersonalization) {
								personalizationToUse = generatedPersonalization;
							}
						}
						if (personalizationToUse) {
							params.push('emphasize=$' + personalizationToUse);
							sessionStorage.setItem('sesPz' + superFromUrl, personalizationToUse);
							sessionStorage.setItem('oldSesPz' + superFromUrl, personalizationToUse);
							didPersonalize = true;
						} else {
							// sessionStorage.setItem('sesPz' + superFromUrl, '');
							// sessionStorage.setItem('oldSesPz' + superFromUrl, '');
						}
					}
				}
			} catch (e) {
				//personalization error, lets not fret
			}
		}
		if (this.state.headerFooterData.JSON.wasParms.isPersonalizationOn != 'true') {
			try {
				var superFromUrl = this.getSuperFromUrl();
				if (window.Modernizr && Modernizr.localstorage && Modernizr.sessionstorage && superFromUrl) {
					sessionStorage.setItem('sesPz' + superFromUrl, '');
					sessionStorage.setItem('oldSesPz' + superFromUrl, '');
				}
			} catch (e) { }
		}
		//end personalization
		//add intl param if needed
		if (
			this.state.country != 'US' &&
			(typeof window == 'undefined' || (Cookies.get('country') && Cookies.get('country') != 'US')) &&
			this.determineSwitchPath().indexOf('/p/') == -1
		) {
			var foundFacet = false;
			var foundIntlFacet = false;
			for (var i = 0; i < params.length; i++) {
				var thisParam = params[i];
				if (thisParam.indexOf('facet=') == 0) {
					facetIndex = i;
					foundFacet = true;
					var thisParamValue = thisParam.split('=')[1];
					var facets = [];
					facets = thisParamValue.split('|');
					for (var j = 0; j < facets.length; j++) {
						if (facets[j].indexOf('dil_shipinternational') == 0) {
							foundIntlFacet = true;
						}
					}
					if (!foundIntlFacet) {
						facets.push('dil_shipinternational:Y');
						didInternationalize = true;
						facets.sort();
					}
				}
			}
			if (!foundFacet) {
				params.push('facet=dil_shipinternational:Y');
				didInternationalize = true;
			} else {
				params[facetIndex] = 'facet=' + facets.join('|');
			}
		}
		//end intl param if needed
		if (didPersonalize || didInternationalize || didSeoColor || didAddSort) {
			params.sort();
			urlToGoTo = decodeURIComponent(currentUrl.split(window.location.hostname)[1].split('?')[0]) + '?' + params.join('&');
			if (
				String(window.location).indexOf('#') > -1 &&
				String(window.location)
					.split('#')[1]
					.indexOf('q=') == 0
			) {
				urlToGoTo += '#' + String(window.location).split('#')[1];
			}
		}
		if (urlToGoTo != currentUrl) {
			this.props.history.replace(urlToGoTo);
		}
		return didPersonalize || didInternationalize || didSeoColor || didAddSort;
	}
	generatePersonalizationParam (superCatId) {
		if (!superCatId) {
			superCatId = this.getSuperFromUrl();
		}
		var returnPersonalization = false;
		try {
			if (typeof window != 'undefined' && this.state.updatedFromCookies) {
				var numAttrs = 0;
				var personalizationForSuper = '';

				var favoritesDataForSuper = localStorage.getItem('myFavoritespersonalization' + superCatId);
				if (favoritesDataForSuper != null) {
					$(favoritesDataForSuper.split('|')).each(function () {
						var thisPersonalizationAttr = this;
						if (personalizationForSuper.length > 0) personalizationForSuper += '|';
						personalizationForSuper +=
							'mfb' +
							encodeURIComponent(thisPersonalizationAttr.split('&amp;').join('&'))
								.split('%C2%AE')
								.join('%25C2%25AE')
								.split('%26')
								.join('%2526')
								.split('%2B')
								.join('%252B');
						numAttrs++;
					});
				}

				var personalizationDataForSuper = localStorage.getItem('personalization' + superCatId);
				if (personalizationDataForSuper != null) {
					$(personalizationDataForSuper.split('|')).each(function () {
						var thisPersonalizationAttr = this;
						if (numAttrs <= 3) {
							if (
								thisPersonalizationAttr
									.split('&amp;')
									.join('&')
									.split(';')[1] *
								1 >=
								3
							) {
								var newSuperBrand = encodeURIComponent(
									thisPersonalizationAttr
										.split('&amp;')
										.join('&')
										.split(';')[0]
								)
									.split('%C2%AE')
									.join('%25C2%25AE')
									.split('%26')
									.join('%2526')
									.split('%2B')
									.join('%252B');
								if (personalizationForSuper.indexOf(newSuperBrand) == -1) {
									if (personalizationForSuper.length > 0) personalizationForSuper += '|';
									personalizationForSuper += newSuperBrand;
									numAttrs++;
								}
							}
						}
					});
				}

				//new standard size addition.  If this breaks still let the brands through...
				//just in case...
				if (window.ssPersonalizationActive) {
					try {
						if (personalizationForSuper.length > 0) {
							var ssDataForSuper = localStorage.getItem('ss' + superCatId);
							var maxNumberOfSS = 10;
							//          maxNumberOfSS = 3;//for testing locally
							if (ssDataForSuper != null) {
								var ssDataSplit = ssDataForSuper.split('|');
								// var numberSSAdded = 0;
								for (var iSS = 0; iSS < Math.min(maxNumberOfSS, ssDataSplit.length); iSS++) {
									var thisSS = ssDataSplit[iSS].split(';')[0];
									var thisSSScore = ssDataSplit[iSS].split(';')[1] * 1;
									if (personalizationForSuper.length > 0) personalizationForSuper += '|';
									personalizationForSuper += `ss:${thisSS}`;
									// numberSSAdded++;
								}
							}
						}
					} catch (e) {
						console.log(e);
					}
				}

				returnPersonalization = personalizationForSuper;
			}
		} catch (e) {
			console.log(e);
		}
		return returnPersonalization;
	}
	getCurrentPersonalizationParam (superCatId) {
		var returnPersonalization = false;
		try {
			return sessionStorage.getItem(`sesPz${superCatId}`);
		} catch (e) { }
		return returnPersonalization;
	}
	compareNewCacheCallVariable (newState) {
		try {
			var existingCacheCallVariable = this.state.headerFooterData.JSON.wasParms.cacheCallVariable;
			var newCacheCallVariable =
				(newState && newState.headerFooterData && newState.headerFooterData.JSON && newState.headerFooterData.JSON.wasParms.cacheCallVariable) ||
				this.state.headerFooterData.JSON.wasParms.cacheCallVariable;
			if (existingCacheCallVariable != newCacheCallVariable) {
				window.forceRefresh = true;
			}
		} catch (e) { }
	}
	setState (newState) {
		this.compareNewCacheCallVariable(newState);
		this.updateStateWithCookies(true);
		super.setState(newState);
	}
	fetchDataAsync (goToTop) {
		clearTimeout(window.heightAutoTimeout);
		$('body').height(25000);
		// this.setModalInfo(undefined);
		var stateToSetBeforeSend = { modalInfo: undefined, transition: true, handleMenuOpen: false };
		try {
			if (this.determineSwitchPath().indexOf(':pathModifier') > -1 && !this.seoColorInfo().seoColorDisplay) {
				var navStatePath = this.state.navState.split('#')[0].split('?')[0];
				var pathModifier = navStatePath.split('/')[navStatePath.split('/').length - 1];
				var facetsJSON = false;
				if (this.state.contentData && this.state.contentData.layoutContent && this.state.contentData.layoutContent.facetsJSON) {
					facetsJSON = this.state.contentData.layoutContent.facetsJSON;
				}
				if (facetsJSON && facetsJSON.facetsList) {
					// if we find the appropriate facet, set its info.
					// this.state.contentData.layoutContent.seoColorDisplay;(Short Sleeve)
					// this.state.contentData.layoutContent.seoColorString;(sleeve-length_short-sleeve)
					// this.state.contentData.layoutContent.seoColorValue;(ads_f10529_ntk_cs%3A%22Short+Sleeve%22)
					for (var iFacet = 0; iFacet < facetsJSON.facetsList.length; iFacet++) {
						var thisFacetName = facetsJSON.facetsList[iFacet];
						var thisFacetInfo = facetsJSON[thisFacetName];
						var facetTitleForSeoColorString = thisFacetInfo.facetTitle
							.toLowerCase()
							.split(' ')
							.join('-')
							.split('/')
							.join('-')
							.split('+')
							.join('')
							.split('"')
							.join('')
							.split('#double;')
							.join('');
						if (facetTitleForSeoColorString != 'brand' && facetTitleForSeoColorString != 'size') {
							var theseFacetValues = thisFacetInfo.values;
							for (var iFacetValue = 0; iFacetValue < theseFacetValues.length; iFacetValue++) {
								var thisFacetValue = theseFacetValues[iFacetValue];
								var thisFacetValueForSeoColorString = thisFacetValue.dsp
									.toLowerCase()
									.split(' ')
									.join('-')
									.split('/')
									.join('-')
									.split('+')
									.join('')
									.split('"')
									.join('')
									.split('#double;')
									.join('');
								var seoColorStringForCompare = facetTitleForSeoColorString + '_' + thisFacetValueForSeoColorString;
								if (facetTitleForSeoColorString == 'color') {
									seoColorStringForCompare = thisFacetValueForSeoColorString;
								}
								if (pathModifier == seoColorStringForCompare) {
									this.state.contentData.layoutContent.seoColorDisplay = thisFacetValue.dsp;
									this.state.contentData.layoutContent.seoColorString = pathModifier;
									this.state.contentData.layoutContent.seoColorValue = thisFacetValue.val;
								}
							}
						}
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
		this.setState(stateToSetBeforeSend);
		if (oneDataCall) {
			var axiosConfig = {
				method: 'get',
				url: `/data${this.state.navState.split('#')[0] || '/'}`,
				maxRedirects: 99,
			};
			axios(axiosConfig).then((response) => {
				this.setState(response.data);
			});
		} else {
			var axiosConfig = {
				method: 'get',
				url: `/data${this.state.navState.split('#')[0] || '/'}`,
				maxRedirects: 99,
			};
			var axiosConfigHeaderFooter = {
				method: 'get',
				url: `/data/headerFooterData`,
				maxRedirects: 0,
			};
			Promise.all([axios(axiosConfig), axios(axiosConfigHeaderFooter)].concat(this.preloadRequiredChunks(true)))
				.then((ajaxResponses) => {
					if (ajaxResponses[0].config.url.split('/data').join('') == this.state.navState.split('#')[0]) {
						var reloadInsteadOfRender = false;
						try {
							//disable CT recording
							if (!this.state.currentCTPauseResume) {
								this.state.currentCTPauseResume = true;
								if (typeof window.CTPauseResume != 'undefined') window.CTPauseResume(true);
							}
						} catch (e) { }
						var arrayToMerge = [];
						for (var i = 0; i < ajaxResponses.length; i++) {
							arrayToMerge.push(ajaxResponses[i].data);
							try {
								if (typeof ajaxResponses[i].data != 'object' && ajaxResponses[i].data.indexOf('dillards_vp_page_comment_used_for_ajax') > -1) {
									reloadInsteadOfRender = true;
									window.location.reload();
								}
							} catch (e) { }
						}
						if (!reloadInsteadOfRender) {
							//to handle for redirects on /data calls.  This will update the url to where the data call was redirected
							try {
								var requestedUrl = ajaxResponses[0].config.url.split('/data/')[1];
								var responseUrl = requestedUrl;
								if (ajaxResponses[0].request.responseURL && ajaxResponses[0].request.responseURL.indexOf('/data/') > -1) {
									responseUrl = ajaxResponses[0].request.responseURL.split('/data/')[1];
								}
								if (requestedUrl != responseUrl) {
									responseUrl = responseUrl.startsWith('//') ? responseUrl.substring(1) : responseUrl;
									//the data call got redirected from one data call to another
									//now we need to update url to reflect the new url
									this.state.navState = '/' + responseUrl;
									this.props.history.replace('/' + responseUrl);
								}
							} catch (e) {
								// console.log(e);
								// console.log(ajaxResponses[0]);
							}

							arrayToMerge.push({ transition: false });
							this.state.contentSwitchPath = this.determineSwitchPath();
							var newStateHolder = _.merge(...arrayToMerge);
							newStateHolder.setHeightAuto = true;
							if (typeof newStateHolder.contentData != 'object') {
								newStateHolder.contentData = {
									JSONError: 'error',
								};
							}
							newStateHolder.contentData.navState = this.state.navState;
							if (typeof newStateHolder.headerFooterData != 'object') {
								newStateHolder.headerFooterData = fallbackHeaderFooterJSON;
							}
							this.resetDataLayerInfo();
							if (this.state.navigationToggles.keepPos) {
								window.scrollTo(0, this.state.windowPosition);
							} else if (goToTop && !this.state.navigationToggles.keepPos) {
								window.scrollTo(0, 0);
							} else {
								try {
									if (window.historyPlaces && window.historyPlaces[String(window.location)]) {
										// window.scrollTo(0, window.historyPlaces[String(window.location)]);
										newStateHolder.goToThisScrollPositionAfterRender = window.historyPlaces[String(window.location)];
										delete window.historyPlaces[String(window.location)];
									} else {
										window.scrollTo(0, 0);
									}
								} catch (e) {
									window.scrollTo(0, 0);
								}
							}

							//clean up filter open specially here.
							//going to nav page with no results or not a nav page
							if (
								(newStateHolder.contentData &&
									newStateHolder.contentData.layoutContent &&
									newStateHolder.contentData.layoutContent.resultListIsPresent != 'true') ||
								(newStateHolder.contentData && !newStateHolder.contentData.layoutContent)
							) {
								this.state.navigationToggles.filterOpen = false;
								document.body.classList.remove('filter-open');
							}

							if (
								typeof newStateHolder == 'object' &&
								newStateHolder.contentData &&
								typeof newStateHolder.contentData == 'object' &&
								newStateHolder.contentData.JSONRedirect
							) {
								this.props.history.replace(newStateHolder.contentData.JSONRedirect);
							} else {
								this.setState(newStateHolder);
							}
							//          $('body').height('auto');
							this.triggerCustomPageView();
						}
					}
				})
				.catch((err) => {
					window.forceRefresh = true;
					window.fetchDataAsyncError = err;
					this.setState({
						transition: false,
						contentData: {
							JSONError: 'error',
						},
					});
				});
		}
	}
	fetchDataAsyncQuickView (extraQVProps) {
		var axiosConfig = {
			method: 'get',
			url: `/data${this.state.navState.split('#')[0]}`,
			maxRedirects: 0,
		};
		Promise.all([axios(axiosConfig)].concat(this.preloadRequiredChunks(true)))
			.then((ajaxResponses) => {
				if (ajaxResponses[0].config.url.split('/data').join('') == this.state.navState) {
					var reloadInsteadOfRender = false;
					try {
						if (typeof ajaxResponses[0].data != 'object' && ajaxResponses[0].data.indexOf('dillards_vp_page_comment_used_for_ajax') > -1) {
							reloadInsteadOfRender = true;
							window.location.reload();
						}
					} catch (e) { }
					var newStateObject = {
						quickViewData: ajaxResponses[0].data,
					};
					if (typeof newStateObject.quickViewData != 'object' || newStateObject.quickViewData.JSONError) {
						newStateObject.quickViewData = {
							contentData: {
								productLayout: 'QuickViewError',
							},
						};
						this.appendHiddenErrorLoggingForQuickViewError();
					} else {
						newStateObject.quickViewData.contentData.navState = this.state.navState;
						newStateObject.quickViewData.contentData.oldProductLayout = newStateObject.quickViewData.contentData.productLayout;
						if (
							newStateObject.quickViewData.contentData.productLayout == 'BadCatentryId' ||
							newStateObject.quickViewData.contentData.productLayout == 'NotAvailable' ||
							newStateObject.quickViewData.contentData.unavailableMessage
						) {
							newStateObject.quickViewData.contentData.productLayout = 'QuickViewNotAvailable';
						} else if (newStateObject.quickViewData.contentData.JSONError) {
							newStateObject.quickViewData.contentData.productLayout = 'QuickViewNotAvailable';
						} else {
							newStateObject.quickViewData.contentData.productLayout = 'QuickViewDisplay';
						}

						if (extraQVProps) {
							newStateObject.quickViewData.contentData.extraQVProps = extraQVProps;
						}
					}
					if (!reloadInsteadOfRender) {
						this.setState(newStateObject);
					}
				}
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					var ajaxResponse = err.response;
					if (ajaxResponse.config.url.split('/data').join('') == this.state.navState) {
						var newStateObject = {
							quickViewData: ajaxResponse.data,
						};
						if (
							newStateObject.quickViewData.contentData.productLayout == 'BadCatentryId' ||
							newStateObject.quickViewData.contentData.productLayout == 'NotAvailable' ||
							newStateObject.quickViewData.contentData.unavailableMessage
						) {
							newStateObject.quickViewData.contentData.productLayout = 'QuickViewNotAvailable';
						} else if (newStateObject.quickViewData.contentData.JSONError) {
							newStateObject.quickViewData.contentData.productLayout = 'QuickViewNotAvailable';
						} else {
							newStateObject.quickViewData.contentData.productLayout = 'QuickViewDisplay';
						}
						if (extraQVProps) {
							newStateObject.quickViewData.contentData.extraQVProps = extraQVProps;
						}
						this.setState(newStateObject);
					}
				} else {
					var newStateObject = {};
					newStateObject.quickViewData = {
						contentData: {
							productLayout: 'QuickViewError',
						},
					};
					this.setState(newStateObject);
					this.appendHiddenErrorLoggingForQuickViewError(err, 'first-catch');
				}
			})
			.catch((err) => {
				var newStateObject = {};
				newStateObject.quickViewData = {
					contentData: {
						productLayout: 'QuickViewError',
					},
				};
				this.setState(newStateObject);
				this.appendHiddenErrorLoggingForQuickViewError(err, 'second-catch');
			});
	}
	appendHiddenErrorLoggingForQuickViewError (err, catchLoc) {
		//this is to help track through clicktale to see if we can narrow down why error quickview is being thrown
		try {
			$('body').append(
				`<div style="display:none!important;" class="hidden hide">
          <div class="qv-error-logging">
           ${String(window.location)}
          </div>
          ${
						err
					? `
            <div class="qv-error-logging-err ${catchLoc}">
              ${err}
            </div>
          `
					: `
            <div class="qv-error-logging-other">
              response was not JSON
            </div>
          `
				}
        </div>`
			);
		} catch (e) { }
	}
	closeQuickView () {
		// scroll to then remove local storage scroll position (fix for modal return to position), remove viewport height on html (for iOS 15 viewport height bug)
		if (this.state.siteFormat == 'mobile') {
			document.querySelector('html').removeAttribute('style');
			let mobileQvScrollTo = '';
			if (window.Modernizr && Modernizr.sessionstorage && sessionStorage.getItem('qvModalPos')) {
				mobileQvScrollTo = sessionStorage.getItem('qvModalPos');
				sessionStorage.removeItem('qvModalPos');
			} else if (Cookies.get('qvModalPos')) {
				mobileQvScrollTo = Cookies.get('qvModalPos');
				Cookies.remove('qvModalPos');
			}
			if (mobileQvScrollTo !== '') {
				$('.ReactModal__Body--open #root').css('position', 'relative');
				$('html, body').animate(
					{
						scrollTop: mobileQvScrollTo,
					},
					0
				);
			}

		}

		window.history.go(-1);

	}

	unBindFrozenRoot () {
		// $('#root').unbind('touchmove.forIOS');
	}
	afterOpenModal () {
		$('.ReactModal__Content .i-close').parent('button').trigger('focus');

	}
	setCookie (name, value) {
		var domain = window.location.hostname;
		if (window.location.hostname != 'localhost') {
			domain = '.' + window.location.hostname;
		}
		Cookies.set(name, value, {
			path: '/',
			domain: domain,
		});
		console.log("Cookie Name: " + name + "/ Value: " + value);
	}
	updateStateWithCookies (doNotReRender) {
		var needToUpdateState = false;
		this.state.updatedFromCookies = true;
		//akamaiWait cookie "cma"
		try {
			var currentAkamaiWaitCookieValue = this.getCookie('cma');
			if (!currentAkamaiWaitCookieValue) {
				var domain = window.location.hostname;
				if (window.location.hostname != 'localhost') {
					domain = '.' + window.location.hostname;
				}
				Cookies.set('cma', '02141', {
					path: '/',
					domain: domain,
				});
			}
		} catch (e) {
			//console.log(e);
		}
		//set marketing cookies
		try {
			if (this.getURLParamValue('googleShop') == 'Y' || this.getCookie('googleShop') == 'Y') {
				Cookies.set('googleShop', 'Y', {
					path: '/',
					domain: '.' + window.location.hostname,
				});
				Cookies.set('country', 'US', {
					path: '/',
					domain: '.' + window.location.hostname,
				});
				Cookies.set('currency', 'USD', {
					path: '/',
					domain: '.' + window.location.hostname,
				});
			}
			if (this.getURLParamValue('cptc')) {
				Cookies.set('cptc', this.getURLParamValue('cptc'), {
					path: '/',
					domain: '.' + window.location.hostname,
				});
			}
			var cm_mmc = this.getURLParamValue('cm_mmc');
			if (cm_mmc) {
				if (cm_mmc.indexOf('criteo') != -1) {
					Cookies.set('criteo_dd', cm_mmc, {
						expires: 7,
						path: '/',
						domain: '.' + window.location.hostname,
					});
					Cookies.set('criteo_ss', 1, {
						path: '/',
						domain: '.' + window.location.hostname,
					});
				}
			}

		} catch (e) {
			console.log(e);
		}

		var newCountry = this.getCookie('country');
		if (window.dillardsIntlObj && window.dillardsIntlObj.countryCode()[newCountry] === undefined) {
			newCountry = 'US';

			if (window.location.hostname != 'localhost') {
				domain = '.' + window.location.hostname;
			}
			Cookies.set('country', 'US', {
				path: '/',
				domain: domain,
			});
		}
		if (newCountry && newCountry != this.state.country) {
			this.state.country = newCountry;
			needToUpdateState = true;
			if (this.state.country != 'US' && this.state.siteFormat != 'mobile') {
				if (window.dillardsIntlObj.countryCode()[this.state.country]) {
					var wlcmeCookieExist = this.getCookie('wlcme');
					if (typeof wlcmeCookieExist === 'undefined') {
						var countryObj = window.dillardsIntlObj.countryCode()[this.state.country],
							welcomeMatURL = countryObj.getWelcomeMatURL;
						var wlcme51 = document.createElement('script');
						wlcme51.src = welcomeMatURL;
						wlcme51.type = 'text/javascript';
						document.getElementsByTagName('head')[0].appendChild(wlcme51);
						if (window.Modernizr && Modernizr.sessionstorage) {
							sessionStorage.setItem('calledIntlWelcome', true);
							// Dillards.setCookieOrWebStorage("sessionStorage", "calledIntlWelcome", true);
						}
					}
				}
			}
		}
		var newCurrency = this.getCookie('currency');
		if (newCurrency && newCurrency != this.state.currency) {
			this.state.currency = newCurrency;
			needToUpdateState = true;
		} else if (typeof newCurrency == 'undefined') {
			try {
				var currencyObj = window.dillardsIntlObj.countryCode()[newCountry],
					currencyCode = currencyObj.getCurrencyCode;
				this.state.currency = currencyCode;
				needToUpdateState = true;
				var domain = window.location.hostname;
				if (window.location.hostname != 'localhost') {
					domain = '.' + window.location.hostname;
				}
				Cookies.set('currency', currencyCode, {
					path: '/',
					domain: domain,
				});
			} catch (e) { }
		}

		var newUserInformation = this.getUserInformation();
		if (newUserInformation && !_.isEqual(this.state.myDillardsInfo.userInformation, newUserInformation)) {
			this.state.myDillardsInfo.userInformation = newUserInformation;
			needToUpdateState = true;
		}

		var savedMiniCartTotals = this.getCookie('301_CVMINICART');

		if (savedMiniCartTotals && savedMiniCartTotals.indexOf('~~~') > -1) {
			var savedMiniCartTotalsArray = savedMiniCartTotals
				.split('@')
				.join('')
				.split('items')
				.join('')
				.split('amount')
				.join('')
				.split('~~~');
			var newCartItemCount = savedMiniCartTotalsArray[1] * 1;
			var newCartTotal = savedMiniCartTotalsArray[2] * 1;
			if (newCartItemCount != this.state.myDillardsInfo.miniCartInfo.cartItemCount) {
				this.state.myDillardsInfo.miniCartInfo.cartItemCount = newCartItemCount;
				needToUpdateState = true;
			}
			if (newCartTotal != this.state.myDillardsInfo.miniCartInfo.cartTotal) {
				this.state.myDillardsInfo.miniCartInfo.cartTotal = newCartTotal;
				needToUpdateState = true;
			}
		}
		window.DILLARDS = window.DILLARDS || {};
		window.DILLARDS.country = this.state.country;
		window.DILLARDS.currency = this.state.currency;

		try {
			var myDillardsPersonalInfo = this.state.myDillardsInfo.userInformation;
			var tempUserObject = { userId: myDillardsPersonalInfo.userId };
			if (tempUserObject.userId == '-1002') {
				tempUserObject.userType = 'Guest';
			} else {
				tempUserObject.userType = 'Registered';
				tempUserObject.firstName = myDillardsPersonalInfo.name;
				tempUserObject.lastName = myDillardsPersonalInfo.lastName;
				tempUserObject.logonId = myDillardsPersonalInfo.logonId;
			}
			this.setDataLayerInfo('user', tempUserObject);
			this.setDataLayerInfo('system', { siteType: this.state.siteFormat[0] });
			this.setDataLayerInfo('cartInfo', {
				items: this.state.myDillardsInfo.miniCartInfo.cartItemCount,
				amount: this.state.myDillardsInfo.miniCartInfo.cartTotal.toFixed(2),
			});
		} catch (e) {
			//let's not endanger the user if this breaks, but it shouldn't
		}

		if (needToUpdateState && !doNotReRender) {
			this.setState({});
		}
	}
	updateEspotsWithIntlParam () {
		if (window.DILLARDS.country != 'US') {
			$('.mobile-menu ul a, #quick_links a').each(function () {
				var elem = this;
				if (window.location.origin.indexOf(elem.origin) == -1) return;
				var catlinktext = $(elem).attr('href');
				var catLinkHash = '';
				if (catlinktext.indexOf('#') > 0) {
					catLinkHash = '#' + catlinktext.split('#')[1];
					catlinktext = catlinktext.split('#')[0];
				}
				if (catlinktext.indexOf('dil_shipinternational:Y') < 1) {
					if (catlinktext.indexOf('?') > -1) {
						catlinktext = catlinktext + '&facet=dil_shipinternational:Y';
					} else {
						catlinktext = catlinktext + '?facet=dil_shipinternational:Y';
					}
				}
				$(elem).attr('href', catlinktext + catLinkHash);
			});
			$('.mobile-menu ul a[href^="/c/dresses"]')
				.parent()
				.remove();
		}
	}

	componentDidMount () {
		if(typeof window != 'undefined' && window.devicePixelRatio && window.screen && window.screen.availWidth) {
			window.fullimgpixels = Math.round(window.devicePixelRatio*window.screen.availWidth);
		}
		window.dataLayer = this.state.dataLayer;
		// check cookie, add thing
		var cookieEnabled = (navigator.cookieEnabled) ? true : false;
		if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
		  document.cookie = 'testcookie';
		  cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
		}
		if($('#no-cookies-alert').length < 1){
			var prependTo = $('body');
			if($('.mobilePage').length > 0){
			  prependTo = $('#headerWrap');
			}
		  if (!cookieEnabled) {
			  prependTo.prepend(`<div class="tipytop noscriptmsg"><div data-twist="no-cookies" id="no-cookies-alert" class="alert alert-danger" role="alert"><p>Your browser currently has Cookies disabled. Please turn Cookies on to have the best shopping experience on Dillards.com. Click here to <a href="http://www.dillards.com/html/cookie_disable_instructions.html">enable</a>.</p></div></div>`);

			  if($('.mobilePage').length > 0){
				const divElem = document.getElementById("menu_wrapper");
				if(divElem){
					divElem.style.setProperty('padding-top', document.getElementById("headerWrap").offsetHeight + 'px', 'important');
					if(divElem.classList.contains('mobilePromo')){
						divElem.classList.add("mobilePromo_static");
						divElem.classList.remove("mobilePromo");
					} 
				}
			  }
			}
		}
		if (location !== undefined && location.pathname === '/member/subscriptions') {
			var userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
			if (userInfo && userInfo.name && Cookies.get('WC_USERACTIVITY_' + userInfo.userId)) {
			} else if (Cookies.get('WC_PERSISTENT') && userInfo && userInfo.name) {
				window.location = `/webapp/wcs/stores/servlet/RememberMeLogonFormView?URL=${encodeURIComponent(location.pathname)}`;
			} else {
				window.location = `/webapp/wcs/stores/servlet/LogonForm?rURL=${encodeURIComponent(location.pathname)}`;
			}
		}
		if (location != undefined && location.pathname.indexOf('/search-term/') > -1) {
			var searchTerm = location.pathname.split('/search-term/')[1].split('?')[0];
			if (searchTerm.indexOf('%20') > -1) {
				window.location = `/search-term/${searchTerm.split('%20').join('+')}`;
			}
		}
		this.updateStateWithCookies();
		window.dataLayerReady = true;
		this.updateEspotsWithIntlParam();
		Modal.setAppElement('#root');
		this.contentRecClicks();
		window.reactAxios = axios;
		this.personalizeUrlIfNeeded();
		$('body').height('auto');

		this.toggleFooterLink();
		this.triggerCustomPageView();
	}

	bambuserCheck (url) {
		this.props.history.push(url);
	}

	contentRecClicks () {
		var thisPropsHistory = this.props.history;
		var that = this;
		//for handling clicks on quick view links in content areas.  Should open quick view and fire the manual link click.
		$('body').off('click.contentrec touchend.contentrec');
		$('body').on('click.contentrec touchend.contentrec', '[data-espotname] [data-modal-body*="QuickView"]', function (e) {
			var dataProductId = JSON.parse(
				$(this)
					.data('modal-body')
					.split('(')[1]
					.split(')')[0]
			).catentryId;
			var qvURL = `/p/-/${dataProductId}`;
			try {
				var relativeHref = $(this).data('relative-href');
				if (relativeHref) {
					qvURL = relativeHref.split('?')[0];
					manualLinkClick(relativeHref + 'cm_sp=' + $(this).data('cmsp'));
				}
			} catch (e) {
				// console.log(e);
			}
			if (window.receiveSyndecaMessage && window.syndecaTracking) {
				if (qvURL.indexOf('?') > -1) {
					qvURL += '&';
				} else {
					qvURL += '?';
				}
				qvURL += window.syndecaTracking;
				manualLinkClick(qvURL);
			}
			thisPropsHistory.push(qvURL, { qv: true });
			e.stopPropagation();
			return false;
		});
		//for handling clicks on links in content areas.  This allows them to use the nice front end routing and have things just work.
		$('body').on('click.contentrec', '[data-espotname] [href^="/"], .modal.apo-fpo [href^="/"]', function (e) {
			if (!$(this).is('[data-toggle="modal"]')) {
				var originalLinkToGoTo = $(this).attr('href');
				var linkToGoTo = originalLinkToGoTo;
				if (linkToGoTo.indexOf('faqs-notices-policies') > -1 && String(window.location).indexOf('faqs-notices-policies') > -1) {
					return;
				}
				if (originalLinkToGoTo.indexOf('#') > -1) {
					linkToGoTo = originalLinkToGoTo.split('#')[0];
					if (
						originalLinkToGoTo.indexOf('#q=') > -1 ||
						originalLinkToGoTo.indexOf('/c/catalogs') > -1 ||
						originalLinkToGoTo.indexOf('/c/guides-lookbooks') > -1
					) {
						//faq links
						linkToGoTo = originalLinkToGoTo;
					} else {
						//other links
						var hashes = originalLinkToGoTo.split('#')[1].split('&');
						for (var i = 0; i < hashes.length; i++) {
							var thisHash = hashes[i];
							if (thisHash.indexOf('emphasize=') == 0) {
								if (linkToGoTo.indexOf('?') > -1) {
									linkToGoTo += '&';
								} else {
									linkToGoTo += '?';
								}
								linkToGoTo += thisHash;
							}
							//new arrivals
							if (thisHash.indexOf('facet=') == 0) {
								var theFacetsSplit = thisHash
									.split('facet=')
									.join('')
									.split('%7C')
									.join('|')
									.split('|');
								var theFacetsOut = [];
								var facetMap = facetCovering;
								//see if we have any facets to move across
								for (var j = 0; j < facetCovering.facetKeys.length; j++) {
									var thisFacetArray = facetCovering[facetCovering.facetKeys[j]];
									for (var g = 0; g < thisFacetArray.length; g++) {
										var thisFacetFromArray = thisFacetArray[g];
										for (var t = 0; t < theFacetsSplit.length; t++) {
											if (theFacetsSplit[t] == thisFacetFromArray[0]) {
												theFacetsOut.push(`${facetCovering.facetKeys[j]}%3A%22${thisFacetFromArray[1]}%22`);
											}
										}
									}
								}
								//if we have facets to move across, add em
								if (theFacetsOut.length > 0) {
									if (linkToGoTo.indexOf('?') > -1) {
										linkToGoTo += '&';
									} else {
										linkToGoTo += '?';
									}
									theFacetsOut.sort();
									linkToGoTo += 'facet=' + theFacetsOut.join('|');
								}
							}
						}
					}
					if (linkToGoTo.indexOf('?') > -1) {
						var params = linkToGoTo.split('?')[1].split('&');
						params.sort();
						var linkToGoTo = linkToGoTo.split('?')[0] + '?' + params.join('&');
					}
				}
				try {
					if (that.state.contentData.navState && !$(this).is('#header_desktop_wrapper [data-espotname] a') && !$(this).is('#footer [data-espotname] a')) {
						var linkToGoToAddition = '';
						var dataCmSp = $(this).attr('manual_cm_sp');
						if (dataCmSp) {
							if (linkToGoTo.indexOf('?') > -1) {
								linkToGoToAddition += '&';
							} else {
								linkToGoToAddition += '?';
							}
							linkToGoToAddition += 'cm_sp=' + dataCmSp;
						}
						//manualLinkClick(linkToGoTo + linkToGoToAddition);
					}
				} catch (e) {
					// console.log(e);
				}
				if (!$(this).is('#productLeftRightEspot [data-espotname] a')) {
					thisPropsHistory.push(
						linkToGoTo
							.split('/shop/en/dillards/')
							.join('/c/')
							.trim()
					);
				}
				e.stopPropagation();
				return false;
			}
		});
		//for tracking clicks.  This is intended to catch and fire link clicks for things we think are not bound, currently.
		$('body').off('click.pageviewtracks');
		$('body a[href^="/"]').data('dontfiremanualclick', true);
		$('body').on('click.pageviewtracks', 'a[href^="/"]', function () {
			var firstPageLoad = !that.state.contentData.navState;
			var selectorsToExcludeFromThisBind = [];
			selectorsToExcludeFromThisBind.push('[data-espotname] [href^="/"]');
			selectorsToExcludeFromThisBind.push('.menu a');
			selectorsToExcludeFromThisBind.push('#suggestions-wrapper a');
			var linkToGoTo = $(this).attr('href');
			var dataCmSp = $(this).attr('manual_cm_sp');
			if (dataCmSp) {
				if (linkToGoTo.indexOf('?') > -1) {
					linkToGoTo += '&';
				} else {
					linkToGoTo += '?';
				}
				linkToGoTo += 'cm_sp=' + dataCmSp;
			}
			if (!$(this).data('dontfiremanualclick') && !$(this).is(selectorsToExcludeFromThisBind.join(','))) {
				//manualLinkClick(linkToGoTo);
			}
		});
		$('body').off('click.exitlinktracks');
	}


	buildThresholdList = () => {
		var thresholds = [];
		var numSteps = 20;

		for (let i = 1.0; i <= numSteps; i++) {
			let ratio = i / numSteps;
			thresholds.push(ratio);
		}

		thresholds.push(0);
		return thresholds;
	};
	lazyObserver = () => {
		if (document.querySelectorAll('img.lazy-extended').length > 0) {
		if (window.IntersectionObserver) {
			if (lazyObserver) {
				lazyObserver.disconnect();
			}
			var options = {
				root: null,
					rootMargin: '0px 0px 300px 0px',
				threshold: this.buildThresholdList(),
			};

			var lazyObserver = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !entry.target.classList.contains('already-loaded')) {
						entry.target.src = entry.target.dataset.original;
						entry.target.classList.add('already-loaded');
						lazyObserver.unobserve(entry.target);
					}
				});
			}, options);

			document.querySelectorAll('img.lazy-extended').forEach((lazyImage) => {
				lazyObserver.observe(lazyImage);
			});
		} else {

			window.onload = function () {
				const lazyLoad = function () {
					var lazyImages = [].slice.call(document.querySelectorAll('img.lazy-extended'));
					var advanceLoad = 400;
					lazyImages.forEach(function (lazyImage) {
						lazyImage.src = lazyImage.dataset.original;
						lazyImage.classList.add('already-loaded');
						lazyImages = lazyImages.filter(function (image) {
							return image !== lazyImage;
						});
						if (lazyImages.length === 0) {
							document.removeEventListener('scroll', lazyLoad);
							window.removeEventListener('resize', lazyLoad);
						}
					});
				};
				document.addEventListener('scroll', _.throttle(lazyLoad, 400));
				window.addEventListener('resize', _.throttle(lazyLoad, 400));
			};
		}
		}
	};

	componentDidUpdate () {
		// add preload link for main image on quickviews
		if (this.state.navState.indexOf('/p/') > -1 && (this.state.linkToState != undefined && this.state.linkToState.qv == true)) {
			if (this.state.quickViewData != undefined && Object.keys(this.state.quickViewData.contentData).length > 0 && this.state.quickViewData.contentData.productLayout != 'QuickViewNotAvailable') {
				var qvPreloadLink = document.createElement('link');
				var productNameForLink = this.state.quickViewData.contentData.fullImage.imageNameForUrl;
				var imageNameForLink = this.state.quickViewData.contentData.fullImage.imageNameNoPath;
				qvPreloadLink.rel = 'preload';
				qvPreloadLink.href = '//dimg.dillards.com/is/image/DillardsZoom/mainProduct/' + productNameForLink + '/' + imageNameForLink + '.jpg';
				qvPreloadLink.as = 'image';
				qvPreloadLink.className = 'qvPreloadLink';
				document.head.appendChild(qvPreloadLink);
			} else {
				var qvLinkToRemove = document.querySelectorAll('.qvPreloadLink');
				if (qvLinkToRemove.length > 0) {
					qvLinkToRemove.forEach(function (qvPreloadLink) {
						qvPreloadLink.remove();
					});
				}
		
			}
		}

		if (window.updateOverlay && !this.state.transition) {
			window.contentData = this.state.contentData;
			window.updateOverlay();
		}
		this.addRecentlyViewedProductsSinceRefresh();
		this.lazyObserver();
		if (this.state.scrollToTopAfterRender) {
			this.state.scrollToTopAfterRender = false;
			window.scrollTo(0, 0);
		}
		if (this.state.goToThisScrollPositionAfterRender) {
			window.scrollTo(0, this.state.goToThisScrollPositionAfterRender);
			delete this.state.goToThisScrollPositionAfterRender;
		}
		if (this.state.setHeightAuto) {
			this.state.setHeightAuto = false;
			window.heightAutoTimeout = setTimeout(function () {
				$('body').height('auto');
			}, 1000);
			// $('body').height('auto');
		}
		if (this.state.contentData.navState && this.state.contentData.navState.split('#')[0] != String(window.location).split('#')[0]) {
			try {
				//resume CT recording
				if (this.state.currentCTPauseResume) {
					this.state.currentCTPauseResume = false;
					if (typeof window.CTPauseResume != 'undefined') window.CTPauseResume(false);
				}
			} catch (e) { }
		}
	}
	toggleFooterLink () {
		setTimeout(function() {
			var panelLinks = $('.panel-group')
				.find('.panel-title')
				.find('a'),
				thePanels = $('.panel-collapse');
			$.each(panelLinks, function () {
				panelLinks.off('click.panel');
				panelLinks.on('click.panel', function (e) {
					e.stopPropagation();
					if ($(this).hasClass('collapsed')) {
						$(this).removeClass('collapsed');
						$(this)
							.parents('.panel-default')
							.find('.panel-collapse')
							.removeClass('collapse');
					} else {
						$(this).addClass('collapsed');
						$(this)
							.parents('.panel-default')
							.find('.panel-collapse')
							.addClass('collapse');
					}
				});
			});
			$('.footer-top-link-section.footer-closed').off('click.openFooter');
			$('.footer-top-link-section.footer-closed').on('click.openFooter', function () {
				$(this).toggleClass('footer-open');
			});
		}, 600);
	}
	toggleTransition (forceTransition) {
		if (typeof forceTransition != 'undefined') {
			this.setState({ transition: forceTransition });
		} else {
			this.setState({ transition: !this.state.transition });
		}
	}
	setDataLayerInfo (key, value) {
		this.state.dataLayer[key] = value;
		if (key == 'page') {
			this.state.dataLayer[key].pageTime = this.getFormattedTS();
			if (typeof window != 'undefined' && Object.keys(this.state.contentData).length > 0) {
				var doRerunAdobe = true;
				if (!this.state.quickViewData) {
					doRerunAdobe = window.locationWatch != String(window.location).split('#')[0] || !this.state.contentData.navState;
					window.locationWatch = String(window.location).split('#')[0];
				} else {
					try { $(document).trigger('PDP-QVCall'); } catch (e) {}
					this.triggerCustomPageView();
				}
				if (doRerunAdobe) {
					rerunAdobe();
				}
			}
		}
	}
	resetDataLayerInfo () {
		try {
			//var dataLayerKeysToPreserve = ['user', 'system', 'cartInfo', 'timers'];
			var dataLayerKeys = Object.keys(this.state.dataLayer);
			for (var iKeys = 0; iKeys < dataLayerKeys.length; iKeys++) {
				var thisKey = dataLayerKeys[iKeys];
				//if(dataLayerKeysToPreserve.indexOf(thisKey) == -1){
				delete dataLayer[thisKey];
				//}
			}
		} catch (e) {
			//someone probably messed up datalayer.  no sense in breaking anything else...
		}
	}
	isInternational () {
		return this.state.country != 'US';
	}

	addRecentlyViewedProductsSinceRefresh () {
		try {
			if (window.Modernizr && Modernizr.localstorage) {
				if (this.state.quickViewData && this.state.quickViewData.contentData && this.state.quickViewData.contentData.originalProductName) {
					this.putRecentlyViewedIntoLocalStorage(this.state.quickViewData.contentData);
					$(window).trigger('recentlyViewedUpdate');
				} else if (
					this.state.contentData &&
					this.state.contentData.originalProductName &&
					['ProductDisplay', 'ProductDisplayChanel', 'ProductItemAttrDisplay', 'ProductItemDisplay', 'ProductItemDisplayChanel'].indexOf(
						this.state.contentData.productLayout
					) > -1
				) {
					this.putRecentlyViewedIntoLocalStorage(this.state.contentData);
					$(window).trigger('recentlyViewedUpdate');
				} else if (
					this.state.contentData &&
					this.state.contentData.components &&
					['OutfitDisplay', 'BundleDisplay'].indexOf(this.state.contentData.productLayout) > -1
				) {
					for (var i = 0; i < this.state.contentData.components.length; i++) {
						this.putRecentlyViewedIntoLocalStorage(this.state.contentData.components[i]);
					}
					$(window).trigger('recentlyViewedUpdate');
				}
			}
		} catch (e) { }
	}
	putRecentlyViewedIntoLocalStorage (productData) {
		var newProductToAdd = [];
		newProductToAdd = [
			productData.originalProductName,
			productData.productURL.split('?')[0],
			productData.fullImage.imageName.replace('main', 'productRec'),
			'',
			'',
			'',
			'',
			'',
			productData.isInternational == 'Y' ? 'true' : 'false',
			productData.productId || '',
			'',
			'',
			productData.brandNameForTitle || '',
			productData.productNameForURL,
		];
		var alreadyInRecentlyViewed = false,
			recentlyViewedProducts = [];
		try {
			recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed') || []);
		} catch (e) {
			localStorage.removeItem('recentlyViewed');
		}
		for (var i = 0; i < recentlyViewedProducts.length; i++) {
			var existingRecentlyViewedProduct = recentlyViewedProducts[i];
			if (existingRecentlyViewedProduct[0] == newProductToAdd[0]) {
				alreadyInRecentlyViewed = true;
			}
		}
		if (!alreadyInRecentlyViewed && productData.pricing && productData.pricing.offerPriceMax) {
			if (recentlyViewedProducts.length >= 10) {
				recentlyViewedProducts.pop();
			}
			recentlyViewedProducts.unshift(newProductToAdd);
			localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewedProducts));
		}
	}

	updateUrlWithParams (inputUrl, paramsForUpdate) {
		if (!paramsForUpdate.emphasize && this.getURLParamValue('emphasize')) {
			paramsForUpdate.emphasize = false;
		}

		if (!inputUrl) {
			var returnLink = this.props.location.pathname.split('?')[0];
			var search = this.props.location.search || '';
			if (this.props.location.pathname.indexOf('?') > -1) {
				search =
					'?' +
					this.props.location.pathname
						.split('?')
						.splice(1)
						.join('?');
			}
			inputUrl = returnLink + search;
		}

		//paramsForUpdate example:
		// paramsForUpdate = {
		//   orderBy:4,
		//   pageNumber:false
		// }
		var outputUrl = '';
		try {
			var inputParams = [];
			var outputParams = [];
			var newParams = JSON.parse(JSON.stringify(paramsForUpdate));
			var newParamKeys = Object.keys(newParams);
			if (inputUrl.indexOf('?') > -1) {
				outputUrl = inputUrl.split('?')[0];
				inputParams = inputUrl.split('?')[1].split('&');
				//this check is to see if there is a floating ?
				if (inputParams.length == 1 && inputParams[0].length == 0) {
					inputParams = [];
				}
			} else {
				outputUrl = inputUrl;
			}
			//now we have base url and an array of current inputParams
			for (var i = 0; i < inputParams.length; i++) {
				var thisParam = inputParams[i];
				var thisParamKey = thisParam.split('=')[0];
				var thisParamValue = thisParam.indexOf('=') > -1 ? thisParam.split('=')[1] : '';
				if (newParamKeys.indexOf(thisParamKey) > -1) {
					var newParamValue = newParams[thisParamKey];
					//false means that it was requested to be removed.
					if (newParamValue) {
						//any other value means lets work it.
						if (thisParamKey == 'facet') {
							//facet has to be treated differently...
							var existingFacets = thisParamValue
								.split('%7C')
								.join('|')
								.split('|');
							var newFacets = [];
							var foundNewFacet = false;
							for (var j = 0; j < existingFacets.length; j++) {
								var thisFacet = existingFacets[j];
								if (thisFacet == newParamValue || thisFacet == newParamValue.split('%22').join('"')) {
									foundNewFacet = true;
								} else {
									newFacets.push(thisFacet);
								}
							}
							if (!foundNewFacet) {
								newFacets.push(newParamValue);
							}
							newFacets = newFacets.sort();
							if (newFacets.length > 0) {
								outputParams.push(`${thisParamKey}=${newFacets.join('|')}`);
							}
						} else {
							outputParams.push(`${thisParamKey}=${newParamValue}`);
						}
					}
					delete newParams[thisParamKey];
				} else {
					if (thisParamValue.length > 0) {
						//was not passed to update, let it go as long as it is not empty
						outputParams.push(thisParam);
					}
				}
			}
			for (var i = 0; i < Object.keys(newParams).length; i++) {
				var thisKey = Object.keys(newParams)[i];
				var thisValue = newParams[thisKey];
				if (thisValue) {
					outputParams.push(`${thisKey}=${thisValue}`);
				}
			}
			if (outputParams.length > 0) {
				outputParams = outputParams.sort();
				outputUrl += '?';
				outputUrl += outputParams.join('&');
			}
		} catch (e) {
			console.log(e);
			return inputUrl;
		}
		return outputUrl;
	}
	seoColorInfo () {
		var returnObject = {};
		try {
			if (this.state.contentData.layoutContent && this.state.contentData.layoutContent.seoColorValue) {
				returnObject.seoColorDisplay = this.state.contentData.layoutContent.seoColorDisplay.split('#double;').join('"');
				returnObject.seoColorString = this.state.contentData.layoutContent.seoColorString;
				returnObject.seoColorValue = this.state.contentData.layoutContent.seoColorValue;
			}
		} catch (e) { }
		return returnObject;
	}
	setNavigationToggle (key, value) {
		var oldNavigationToggles = JSON.parse(JSON.stringify(this.state.navigationToggles));
		if (typeof value != 'undefined') {
			//force a toggle
			if (this.state.navigationToggles[key] != value) {
				oldNavigationToggles[key] = value;
				this.setState({ navigationToggles: oldNavigationToggles });
			}
		} else {
			//just toggle
			oldNavigationToggles[key] = !this.state.navigationToggles[key];
			this.setState({ navigationToggles: oldNavigationToggles });
		}
	}
	setWindowPosition (key, value) {
		if (typeof value != 'undefined') {
			this.setState({ windowPosition: value });
		}
	}
	keepHorzFacetOpen (value) {
		//For keeping horz facet open after using keyboard to check the checkbox
		if (typeof value != 'undefined') {
			this.setState({ navigationToggles: { openFacet: value } });
		}
	}

	lastKnownCartTimeFunc (value) {
		if (typeof value != 'undefined') {
			this.state.lastKnownCartTimeState = value;
		}
	}

	getFormattedTS(){

		var date, year, month, day, hour, minute, second;
	  
		date   = new Date();
		year   = '' + date.getFullYear();
		month  = '' + (date.getMonth() + 1);
		day    = '' + date.getDate();
		hour   = '' + date.getHours();
		minute = '' + date.getMinutes();
		second = '' + date.getSeconds();
	  
		if (month.length < 2)  month  = '0' + month;
		if (day.length < 2)    day    = '0' + day;
		if (hour.length < 2)   hour   = '0' + hour;
		if (minute.length < 2) minute = '0' + minute;
		if (second.length < 2) second = '0' + second;
	  
		return month + '.' + day + '.' + year + ' ' + hour + ':' + minute + ':' + second;
	}

	
	triggerCustomPageView () {
		try{
			if(typeof window.ddsAdobeIsReady === 'function'){
				$(document).trigger('triggerPageViewEvent');
			}else{

				var maxAttempts = 200;
				var totalAttempts = 0;
			
				function isAdobeInitiated() {
					if (totalAttempts < maxAttempts) {
						totalAttempts++;
						if (typeof window.ddsAdobeIsReady === 'function') {
							$(document).trigger('triggerPageViewEvent');
							clearInterval(pageViewInterval);
						}
					} else {
						clearInterval(pageViewInterval);
					}
				}
				var pageViewInterval = setInterval(isAdobeInitiated, 50);
			}
		}catch(e){
			console.log(e);
		}
	}
	
	passedAppFunctions () {
		return {
			contentSwitchPath: this.state.contentSwitchPath,
			setModalInfo: this.setModalInfo,
			wasParms: this.state.headerFooterData.JSON.wasParms,
			getCookie: this.getCookie,
			getURLParamValue: this.getURLParamValue,
			closeQuickView: this.closeQuickView,
			afterOpenModal: this.afterOpenModal,
			unBindFrozenRoot: this.unBindFrozenRoot,
			modalInfo: JSON.parse(JSON.stringify(this.state.modalInfo || {})),
			siteFormat: this.state.siteFormat,
			updateStateWithCookies: this.updateStateWithCookies,
			transition: this.state.transition,
			toggleTransition: this.toggleTransition,
			routeInfo: {
				match: this.props.match,
				location: this.props.location,
				history: this.props.history,
			},
			setDataLayerInfo: this.setDataLayerInfo,
			isInternational: this.isInternational,
			getUserInformation: this.getUserInformation,
			myDillardsInfo: JSON.parse(JSON.stringify(this.state.myDillardsInfo)),
			updateUrlWithParams: this.updateUrlWithParams,
			generatePersonalizationParam: this.generatePersonalizationParam,
			getSuperFromUrl: this.getSuperFromUrl,
			navState: this.state.navState,
			seoColorInfo: this.seoColorInfo(),
			recentlyViewedProductsSinceRefresh: this.state.recentlyViewedProductsSinceRefresh,
			navigationToggles: this.state.navigationToggles,
			windowPosition: this.state.windowPosition,
			setNavigationToggle: this.setNavigationToggle,
			setWindowPosition: this.setWindowPosition,
			keepHorzFacetOpen: this.keepHorzFacetOpen,
			quickviewIsOpen: this.state.quickViewData ? true : false,
			fromQv: this.state.fromQv ? true : false,
			setCookie: this.setCookie,
			country: this.state.country,
			currency: this.state.currency,
			lastKnownCartTimeFunc: this.lastKnownCartTimeFunc,
			lastKnownCartTimeState: this.state.lastKnownCartTimeState,
			linkToState: JSON.parse(JSON.stringify(this.state.linkToState || {})),
			bindPreloadRequiredChunk: this.bindPreloadRequiredChunk,
			bambuserCheck: this.bambuserCheck,
			toggleFooterLink: this.toggleFooterLink,
			lazyObserver: this.lazyObserver,
			showTarget: showTarget,
			rerunAdobe: rerunAdobe,
			triggerCustomPageView : this.triggerCustomPageView,
		};
	}
}

export default SharedApp;
