import React from 'react';
require('./tippy-top.scss');

export class SharedTippyTop extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
        this.state.content = '';

        this.updateContent = this.updateContent.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        var returnValue = false;
        if(this.state.content != nextState.content){
            returnValue = true;
        }
        return returnValue;
    }
    updateContent(){
        if(this.state.content != window.permTippyTopContent){
            this.setState({content:window.permTippyTopContent});
        }
    }
    componentDidMount(){
        if(window.permTippyTopContent){
            this.updateContent();
        }
        window.permTippyTopUpdateContent = this.updateContent;
    }
    componentWillUnmount(){
        window.permTippyTopUpdateContent = undefined;
    }
};

export class SharedTippyTopHeight extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
        this.state.height = 0;

        this.checkHeight = this.checkHeight.bind(this);
    }
    checkHeight(){
        var newHeight = $('.perm-tippy-top').height();
        if(this.state.height != newHeight){
            this.setState({height:newHeight});
        }
    }
    componentDidUpdate(){
        this.checkHeight();
    }
    componentDidMount(){
        this.checkHeight();
        $(window).on('resize.permTippyTop', _.throttle(() => {
            this.checkHeight();
        }, 500));
    }
    componentWillUnmount(){
        $(window).off('resize.permTippyTop');
    }
}

export default SharedTippyTop;
