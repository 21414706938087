import React from 'react';
import Portal from '../Portal/Portal';
require('./loading-spinner.scss');

export class LoadingSpinner extends React.Component{
  render(){
    return(
      <Portal>
        <div className="preloader">
          {/*  TEMP TEST, MAYBE GOING BACK IN AT SOME POINT*/}
          {/* <svg className="spinner" width="70px" height="70px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
          </svg> */}
        </div>
      </Portal>
    );
  }
}

export default LoadingSpinner;
