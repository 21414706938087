import React from 'react';

export class SharedError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        if(this.props.error) this.state.error = this.props.error;
        this.renderHiddenError = this.renderHiddenError.bind(this);
    }
    componentDidCatch(error, errorInfo) {
        window.forceRefresh = true;
        this.setState({ "error": error, "errorInfo": errorInfo });
    }
    componentWillReceiveProps(nextProps){
        try{
            if(nextProps.contentData.navState != this.props.contentData.navState){
                this.state.error = false;
            }
            if(nextProps.quickViewData || this.props.quickViewData){
                if(!_.isEqual(nextProps.quickViewData, this.props.quickViewData)){
                    this.state.error = false;
                }
            }
        }catch(e){}
    }
    renderHiddenError(areaString){
        return(
            <div className='hidden'>
                {
                    this.state.errorInfo ?
                    <div>
                        <div className={`node-js-error-${areaString} node-js-error`}>
                            {this.state.error.message || this.state.error.description || 'no-message'}
                        </div>
                        <div className={`node-js-error-details-${areaString} node-js-error-details`}>
                            {this.state.error.stack || 'no-stack'}
                        </div>
                    </div>
                    :
                    <div className={`node-non-js-error-${areaString} node-non-js-error`}>
                        <span className='this-state-error'>
                            {this.state.error}
                        </span>
                        <span className='window-location' >
                            {typeof window != 'undefined' ? String(window.location) || '' : ''}
                        </span>
                        <span className='window-fetchDataAsyncError'>
                            {typeof window != 'undefined' && window.fetchDataAsyncError ? window.fetchDataAsyncError.toString() : ''}
                        </span>
                    </div>
                }
            </div>
        );
    }
}

export default SharedError;
