import React from 'react';
import ReactDOM from 'react-dom';


class Portal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    
    componentDidMount() {
        this.el = document.createElement('div');
        this.portalRoot = document.body;
        // The portal element is inserted in the DOM tree after
        // the Portal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Portal and only render the children when Portal
        // is inserted in the DOM tree.
        this.portalRoot.appendChild(this.el);
        this.setState({});
  }

  componentWillUnmount() {
    this.portalRoot.removeChild(this.el);
  }

  render() {
    if (!this.el) {
        return null
    }
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}

export default Portal;


