import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { cleanTermForURL } from '../../../../../util/cleanTermForURL';

export class Search extends React.Component {
	constructor (props) {
		super(props);
		this.state = {};
		this.state.searchTerm = '';
		this.state.suggestionView = { 'entry': this.emptySearchTermSuggests() };
		this.state.searchBoxActive = false;
		this.state.highlightedSuggestion = { 'number': -1, 'url': undefined };
		this.state.linkClicked = false;
		this.state.ariaExpanded = false;
		this.state.activeDescendant = '';
		this.state.hasLooped = false;
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleLinkDown = this.handleLinkDown.bind(this);
		this.handleLinkUp = this.handleLinkUp.bind(this);
		this.clearSearchHist = this.clearSearchHist.bind(this);
		this.clearSearchTerm = this.clearSearchTerm.bind(this);
		this.injectSearchTermInToBox = this.injectSearchTermInToBox.bind(this);
		this.emptySearchTermSuggests = this.emptySearchTermSuggests.bind(this);
		this.captureEntryForHistory = this.captureEntryForHistory.bind(this);
		this.trackClick = this.trackClick.bind(this);
		this.autosuggestCall = this.autosuggestCall.bind(this);
		this.fallbackCallToBrandandStores = this.fallbackCallToBrandandStores.bind(this);
		//this.hideSearchButton = this.hideSearchButton.bind(this);
	}
	componentWillReceiveProps () {
		if (this.state.suggestionView.entry.length == 0) {
			this.state.suggestionView.entry = this.emptySearchTermSuggests();
		}
	}
	stripSearchCharacters (term) {
		var result = '',
			pattern = "[\\w\\d\\_\\*\\-\\:\\.\\,\\[\\] \\/\\'\\" + String.fromCharCode(201) + "\\" + String.fromCharCode(232) + "\\" + String.fromCharCode(233) + "\\" + String.fromCharCode(234) + "\\" + String.fromCharCode(244) + "\\" + String.fromCharCode(38) + "\\" + String.fromCharCode(43) + "\\" + String.fromCharCode(216) + "\\" + String.fromCharCode(248) + "]";
		// iOS11 Keyboard: Smart Quote
		term = term.replace(/[\u2018\u2019]/g, "'");
		for (var x = 0; x < term.length; x++) {
			var currentChar = term.charAt(x);
			if (currentChar.search(pattern) > -1) result += currentChar;
		}
		return result;
	}
	handleFocus (event) {
		this.setState({ hasLooped: false});
		if (this.props.passedAppFunctions.wasParms.isTypeAhead == 'true') {
			//retrieve static suggestions
			if (window.Modernizr && Modernizr.sessionstorage && !sessionStorage.getItem('staticSuggestions')) {
				var axiosConfig = {
					method: 'get',
					url: '/webapp/wcs/stores/servlet/en/dillards/store/301/sitecontent/SearchComponentCachedSuggestionsView?langId=-1&storeId=301&catalogId=301'
				};
				axios(axiosConfig).then((response) => {

					sessionStorage.setItem("staticSuggestions", JSON.stringify(response.data));
				});
			}
			this.setState({ searchBoxActive: true});
		}
		if (this.state.suggestionView.entry.length == 0) {
			this.state.suggestionView.entry = this.emptySearchTermSuggests();
		} else {
			this.setState({ ariaExpanded: true});
		}
	}
	handleBlur (event) {
		this.state.highlightedSuggestion = { 'number': -1, 'url': undefined };
		this.setState({ hasLooped: true});
		if (this.state.searchBoxActive && !this.state.linkClicked) {
			this.setState({ searchBoxActive: false, ariaExpanded: false});
			if (this.props.handleSearchTap && !event) this.props.handleSearchTap();
		}
	}
	handleLinkDown (event) {
 		sessionStorage.setItem('typeAheadClicked', 'Y');
		this.state.linkClicked = true;
	}
	handleLinkUp (event) {
 		this.state.linkClicked = false;
 		setTimeout(() => {
			this.handleBlur();
		}, 100);
	}
	handleChange (event) {
		this.state.highlightedSuggestion = { 'number': -1, 'url': undefined };
		var newSearchTerm = this.stripSearchCharacters(event.target.value).toLowerCase();
		var oldSearchTerm = this.state.searchTerm;
		var skipNewSuggestions = false;

		if (this.state.searchTerm.trim().length <= 0) {
			if (!this.state.emptyTermSubmit) {
				this.setState({
					emptyTermSubmit: true
				});
			}
			$('.search-area-react').addClass('has-error');
			if (this.state.suggestionView.entry.length == 0) {
				this.setState({ ariaExpanded: false});
			}
		}
		else if (this.state.searchTerm.trim().length > 0) {
			
			if (this.state.emptyTermSubmit) {
				this.setState({
					emptyTermSubmit: false,
					ariaExpanded: true
				});
			}
			$('.search-area-react').removeClass('has-error');
		}

		if (newSearchTerm != oldSearchTerm && newSearchTerm.length <= 60) {
			var registryUrl = false;
			var searchName = false;
			var actionCode = false;
			if (newSearchTerm.indexOf('registry') > 1 || newSearchTerm.indexOf('registries') > 1 || newSearchTerm.indexOf('gift list') > 1) {
				var searchTermForRegistryUrl = newSearchTerm;
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("gift list", " ");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("+", " ");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("registry", "");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("registries", "");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("wedding", "");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("baby", "");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("gift", "");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace("&", "");
				searchTermForRegistryUrl = searchTermForRegistryUrl.replace(" and ", " ");
				searchTermForRegistryUrl = searchTermForRegistryUrl.trim();

				if (searchTermForRegistryUrl.split('+').length > 1 || searchTermForRegistryUrl.split(' ').length > 1) {
					searchName = searchTermForRegistryUrl;
					actionCode = 'A';
					registryUrl = '/webapp/wcs/stores/servlet/RegistrySearchResults?searchName=' + searchTermForRegistryUrl.split(' ').join('+') + '&actionCode=A';
				} else{
					registryUrl = '/registry';
				}
			}
			this.setState({ searchTerm: newSearchTerm, registryUrl: registryUrl, searchName: searchName, actionCode: actionCode });
			if (newSearchTerm.trim() == oldSearchTerm) skipNewSuggestions = true;
		}
		if (this.props.passedAppFunctions.wasParms.isTypeAhead == 'true') {
			if (!skipNewSuggestions && newSearchTerm != oldSearchTerm && newSearchTerm.length <= 60) {
				newSearchTerm = newSearchTerm.trim();
				//ajax some suggestions
				if (newSearchTerm.length > 1) {
					var useUbSuggest = false;
					var ubSuggestUrl = '';
					try {
						if (this.props.passedAppFunctions.wasParms.ubAutoSuggestOn == 'true') {
							ubSuggestUrl = `https://search.unbxd.io/${this.props.passedAppFunctions.wasParms.ubAutoSuggestApiUrl}/autosuggest?q=${newSearchTerm}`;

							var needSuggestedProducts = false;
							//if we want to add suggestedProducts, we need to update this to true
							ubSuggestUrl += `&inFields.count=0&topQueries.count=4&keywordSuggestions.count=0&promotedSuggestion.count=0&popularProducts.count=${needSuggestedProducts ? '4' : '0'}`;
							ubSuggestUrl += '&fields=autosuggest,doctype&version=V2';
							useUbSuggest = true;
						}
					} catch (e) {
					}
					var autoSuggestBaseUrl = `/webapp/wcs/stores/servlet/en/dillards/store/301/sitecontent/SearchComponentAutoSuggestView?storeId=301&langId=-1&catalogId=301&term=${newSearchTerm}`;
					if (useUbSuggest) {
						autoSuggestBaseUrl = ubSuggestUrl;
					}
					this.autosuggestCall(autoSuggestBaseUrl, newSearchTerm, 0, useUbSuggest);
				} else {
					this.setState({ 'suggestionView': { 'entry': this.emptySearchTermSuggests() } });
					if (this.state.suggestionView.entry.length == 0) {
						this.setState({ ariaExpanded: false});
					}
				}
			}
		}
	}

	autosuggestCall (autoSuggestBaseUrl, newSearchTerm, callSolr, useUbSuggest) {
		var axiosConfig = {
			method: 'get',
			url: autoSuggestBaseUrl
		};
		axios(axiosConfig).then((response) => {
			var responseData = response.data;
			var solrAutoSuggestUrl = `/webapp/wcs/stores/servlet/en/dillards/store/301/sitecontent/SearchComponentAutoSuggestView?storeId=301&langId=-1&catalogId=301&term=${newSearchTerm}`;
			try {
				//generate client side suggestions and add those to state
				var entriesHolder = [];
				var staticSuggestions = this.generateStaticSuggestionsView();
				//add this if if we decide to use unbxd for brand suggestions...
				// if(!useUbSuggest){
				//sort the brands by product count, then by name
				staticSuggestions.staticBrands.sort(
					function (a, b) {
						if (a.count == b.count) {
							var aName = a.displayName.toLowerCase();
							var bName = b.displayName.toLowerCase();
							if (aName > bName)
								return 1;
							else
								return -1;
						} else {
							return b.count - a.count;
						}
					});
				for (var iBrand = 0; iBrand < staticSuggestions.staticBrands.length; iBrand++) {
					var thisBrand = staticSuggestions.staticBrands[iBrand];
					entriesHolder.push({ 'cleanTerm': thisBrand.displayName, 'displayName': `${this.wrapSearchTermInSuggestion(thisBrand.displayName)} (Brand)`, 'responseURL': `${thisBrand.responseURL}${window.DILLARDS.country != 'US' ? '?facet=dil_shipinternational:Y' : ''}`, 'count': `${thisBrand.count || 0}` });
				}
				// }
				var refinedList = {};
				if (useUbSuggest) {
					//turn ub suggestions into native suggestions
					var ubContent = JSON.parse(JSON.stringify(responseData));
					responseData = {};
					responseData.suggestionView = [{ entry: [] }];

					var promotedSuggestions = [];
					var topSearchQueries = [];
					var keywordSuggestions = [];
					var popularProducts = [];

					if (ubContent.response && ubContent.response.products && ubContent.response.products.length > 0) {
						for (var i = 0; i < ubContent.response.products.length; i++) {
							// var newSuggestion = {"term":thisUbSuggestion.autosuggest};
							// responseData.suggestionView[0].entry.push(newSuggestion);
							var thisUbSuggestion = ubContent.response.products[i];
							if (thisUbSuggestion.doctype == 'PROMOTED_SUGGESTION') {
								promotedSuggestions.push(this.stripSearchCharacters(thisUbSuggestion.autosuggest).toLowerCase());
							}
							if (thisUbSuggestion.doctype == 'TOP_SEARCH_QUERIES') {
								topSearchQueries.push(this.stripSearchCharacters(thisUbSuggestion.autosuggest).toLowerCase().split('+').join(' '));
							}
							if (thisUbSuggestion.doctype == 'KEYWORD_SUGGESTION') {
								keywordSuggestions.push(this.stripSearchCharacters(thisUbSuggestion.autosuggest).toLowerCase());
							}
							// if(thisUbSuggestion.doctype == 'POPULAR_PRODUCTS'){
							// 	popularProducts.push(thisUbSuggestion.autosuggest.toLowerCase());
							// }
						}
					}

					var allTermsArrayToDedup = [];
					for (var i = 0; i < promotedSuggestions.length; i++) {
						var thisTerm = promotedSuggestions[i];
						if (allTermsArrayToDedup.indexOf(thisTerm) == -1) {
							var newSuggestion = { "term": thisTerm };
							responseData.suggestionView[0].entry.push(newSuggestion);
						}
					}
					for (var i = 0; i < topSearchQueries.length; i++) {
						var thisTerm = topSearchQueries[i];
						if (allTermsArrayToDedup.indexOf(thisTerm) == -1) {
							var newSuggestion = { "term": thisTerm };
							responseData.suggestionView[0].entry.push(newSuggestion);
						}
					}
					for (var i = 0; i < keywordSuggestions.length; i++) {
						var thisTerm = keywordSuggestions[i];
						if (allTermsArrayToDedup.indexOf(thisTerm) == -1) {
							var newSuggestion = { "term": thisTerm };
							responseData.suggestionView[0].entry.push(newSuggestion);
						}
					}

				}
				for (var iTerm = 0; iTerm < responseData.suggestionView[0].entry.length; iTerm++) {
					var thisTerm = responseData.suggestionView[0].entry[iTerm];
					var _cleanTerm = thisTerm.term.split(String.fromCharCode("0153")).join("").split(String.fromCharCode("0174")).join("").split(String.fromCharCode("0038")).join(" and ").split(String.fromCharCode("180")).join("'").split('  ').join(' ').trim(); //remove trademark
					if (!(_cleanTerm in refinedList) && ((_cleanTerm.indexOf('|') == -1) && (_cleanTerm.indexOf(newSearchTerm) > -1) && ((_cleanTerm.trim().toLowerCase().indexOf('chanel') == -1) || _cleanTerm.trim().toLowerCase() == 'chanel'))) {
						refinedList[_cleanTerm] = _cleanTerm;
						entriesHolder.push({ 'cleanTerm': _cleanTerm, 'displayName': `${this.wrapSearchTermInSuggestion(_cleanTerm)}`, 'responseURL': `/search-term/${cleanTermForURL(_cleanTerm)}${window.DILLARDS.country != 'US' ? '?facet=dil_shipinternational:Y' : ''}` });
					}
				}
				// if(!useUbSuggest){
				for (var iStore = 0; iStore < staticSuggestions.staticStores.length; iStore++) {
					var thisStore = staticSuggestions.staticStores[iStore];
					entriesHolder.push({ 'cleanTerm': thisStore.displayName.trim(), 'displayName': `${this.wrapSearchTermInSuggestion(thisStore.displayName)} (Store)`, 'responseURL': `${thisStore.responseURL}` });
				}
				// }
				if (this.state.searchTerm.indexOf('registry') > 1 || this.state.searchTerm.indexOf('registries') > 1 || this.state.searchTerm.indexOf('gift list') > 1) {
					var searchTermForRegistryUrl = this.state.searchTerm;
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("gift list", " ");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("+", " ");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("registry", "");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("registries", "");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("wedding", "");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("baby", "");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("gift", "");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace("&", "");
					searchTermForRegistryUrl = searchTermForRegistryUrl.replace(" and ", " ");
					searchTermForRegistryUrl = searchTermForRegistryUrl.trim();
					var searchURL = '';
					if (searchTermForRegistryUrl.split('+').length > 1 || searchTermForRegistryUrl.split(' ').length > 1) {
						searchURL = `/webapp/wcs/stores/servlet/RegistrySearchResults?searchName=${searchTermForRegistryUrl}&actionCode=A`;
					} else{
						var trackAs = this.state.searchTerm;
						searchURL = '/registry?trackAsSearch='+ trackAs.trim().replace(" ", "+");;
					}
					entriesHolder.push({ 'displayName': `View all results for "${this.wrapSearchTermInSuggestion(this.state.searchTerm.trim())}"`, 'responseURL': `${searchURL}` });
				} else {
					entriesHolder.push({ 'cleanTerm': this.state.searchTerm.trim(), 'displayName': `View all results for "${this.wrapSearchTermInSuggestion(this.state.searchTerm.trim())}"`, 'responseURL': `/search-term/${cleanTermForURL(this.state.searchTerm)}${window.DILLARDS.country != 'US' ? '?facet=dil_shipinternational:Y' : ''}` });
				}
				for (var iEntry = 0; iEntry < entriesHolder.length; iEntry++) {
					var thisEntry = entriesHolder[iEntry];
					// manualLinkClick(`/search-term/${this.state.searchTerm}?cm_sp=searchbox-_-term-_-${this.state.searchTerm}`);
					// these new fields are being added here to create the above tag.  The two things we need are the type and the term.  
					var cleanDisplayName = thisEntry.displayName.split('View all results for ').join('').split('<span class="hilite-term">').join('').split('</span>').join('').trim().toLowerCase().split("&").join("and").replace(/[^a-zA-Z ]+/g, "").split("  ").join(" ").split(" ").join("+").split('"').join('');
					var cmClickType = 'term';
					if (thisEntry.responseURL.indexOf('/brand/') > -1) {
						cmClickType = 'brand';
					}
					if (thisEntry.responseURL.indexOf('/stores/') > -1) {
						cmClickType = 'store';
					}
					thisEntry.cmForLinkClick = `searchbox-_-${cmClickType}-_-${cleanDisplayName}`;
 
				}
				this.setState({ 'suggestionView': { 'entry': entriesHolder }, searchHistoryActive: false, ariaExpanded: true });
				//this.hideSearchButton()
			} catch (e) {
				console.log(e);
				this.setState({ 'suggestionView': { 'entry': this.emptySearchTermSuggests() } });
			}
		})
			.catch(err => {
				this.fallbackCallToBrandandStores();
				//This was setup to fallback to solr incase unbxd call failed, leaving it in just in case
				/*var solrAutoSuggestUrl = `/webapp/wcs/stores/servlet/en/dillards/store/301/sitecontent/SearchComponentAutoSuggestView?storeId=301&langId=-1&catalogId=301&term=${newSearchTerm}`;
				if(callSolr == 0){
					this.autosuggestCall(solrAutoSuggestUrl, newSearchTerm, 1, false);
				}else if (callSolr = 1){
					this.setState({'suggestionView':{'entry':this.emptySearchTermSuggests()}});
				}*/

			});

	}

	fallbackCallToBrandandStores () {
		var staticSuggestions = this.generateStaticSuggestionsView();
		var entriesHolder = [];
		staticSuggestions.staticBrands.sort(
			function (a, b) {
				if (a.count == b.count) {
					var aName = a.displayName.toLowerCase();
					var bName = b.displayName.toLowerCase();
					if (aName > bName)
						return 1;
					else
						return -1;
				} else {
					return b.count - a.count;
				}
			});
		for (var iBrand = 0; iBrand < staticSuggestions.staticBrands.length; iBrand++) {
			var thisBrand = staticSuggestions.staticBrands[iBrand];
			entriesHolder.push({ 'cleanTerm': thisBrand.displayName, 'displayName': `${this.wrapSearchTermInSuggestion(thisBrand.displayName)} (Brand)`, 'responseURL': `${thisBrand.responseURL}${window.DILLARDS.country != 'US' ? '?facet=dil_shipinternational:Y' : ''}`, 'count': `${thisBrand.count || 0}` });
		}
		// }
		//		var refinedList = {};
		//		for(var iTerm = 0; iTerm < responseData.suggestionView[0].entry.length; iTerm++){
		//			var thisTerm = responseData.suggestionView[0].entry[iTerm];
		//			var _cleanTerm = thisTerm.term.split(String.fromCharCode("0153")).join("").split(String.fromCharCode("0174")).join("").split(String.fromCharCode("0038")).join(" and ").split(String.fromCharCode("180")).join("'").split('  ').join(' ').trim(); //remove trademark
		//			if (!(_cleanTerm in refinedList) && ((_cleanTerm.indexOf('|') == -1) && ((_cleanTerm.trim().toLowerCase().indexOf('chanel') == -1) || _cleanTerm.trim().toLowerCase() == 'chanel'))) {
		//				refinedList[_cleanTerm] = _cleanTerm;
		//				entriesHolder.push({'cleanTerm': _cleanTerm, 'displayName':`${this.wrapSearchTermInSuggestion(_cleanTerm)}`,'responseURL':`/search-term/${cleanTermForURL(_cleanTerm)}${window.DILLARDS.country != 'US' ? '?facet=dil_shipinternational:Y' : ''}`});
		//			}
		//		}
		// if(!useUbSuggest){
		for (var iStore = 0; iStore < staticSuggestions.staticStores.length; iStore++) {
			var thisStore = staticSuggestions.staticStores[iStore];
			entriesHolder.push({ 'cleanTerm': thisStore.displayName.trim(), 'displayName': `${this.wrapSearchTermInSuggestion(thisStore.displayName)} (Store)`, 'responseURL': `${thisStore.responseURL}` });
		}
		// }
		if (this.state.searchTerm.indexOf('registry') > 1 || this.state.searchTerm.indexOf('registries') > 1 || this.state.searchTerm.indexOf('gift list') > 1) {
			var searchTermForRegistryUrl = this.state.searchTerm;
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("gift list", " ");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("+", " ");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("registry", "");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("registries", "");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("wedding", "");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("baby", "");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("gift", "");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace("&", "");
			searchTermForRegistryUrl = searchTermForRegistryUrl.replace(" and ", " ");
			searchTermForRegistryUrl = searchTermForRegistryUrl.trim();
			var searchURL = '';
			if (searchTermForRegistryUrl.split('+').length > 1 || searchTermForRegistryUrl.split(' ').length > 1) {
				searchURL = `/webapp/wcs/stores/servlet/RegistrySearchResults?searchName=${searchTermForRegistryUrl}&actionCode=A`;
			} else{
				searchURL = '/registry';
			}
			entriesHolder.push({ 'displayName': `View all results for "${this.wrapSearchTermInSuggestion(this.state.searchTerm.trim())}"`, 'responseURL': `${searchURL}` });
		} else {
			entriesHolder.push({ 'cleanTerm': this.state.searchTerm.trim(), 'displayName': `View all results for "${this.wrapSearchTermInSuggestion(this.state.searchTerm.trim())}"`, 'responseURL': `/search-term/${cleanTermForURL(this.state.searchTerm)}${window.DILLARDS.country != 'US' ? '?facet=dil_shipinternational:Y' : ''}` });
		}
		for (var iEntry = 0; iEntry < entriesHolder.length; iEntry++) {
			var thisEntry = entriesHolder[iEntry];
			// manualLinkClick(`/search-term/${this.state.searchTerm}?cm_sp=searchbox-_-term-_-${this.state.searchTerm}`);
			// these new fields are being added here to create the above tag.  The two things we need are the type and the term.  
			var cleanDisplayName = thisEntry.displayName.split('View all results for ').join('').split('<span class="hilite-term">').join('').split('</span>').join('').trim().toLowerCase().split("&").join("and").replace(/[^a-zA-Z ]+/g, "").split("  ").join(" ").split(" ").join("+").split('"').join('');
			var cmClickType = 'term';
			if (thisEntry.responseURL.indexOf('/brand/') > -1) {
				cmClickType = 'brand';
			}
			if (thisEntry.responseURL.indexOf('/stores/') > -1) {
				cmClickType = 'store';
			}
			thisEntry.cmForLinkClick = `searchbox-_-${cmClickType}-_-${cleanDisplayName}`;
		}
		this.setState({ 'suggestionView': { 'entry': entriesHolder }, searchHistoryActive: false });
	}
	generateStaticSuggestionsView () {
		var staticSuggestionsView = { 'staticBrands': [], 'staticStores': [] };
		var searchTermToCheckAgainst = this.state.searchTerm;
		if (window.Modernizr && Modernizr.sessionstorage && sessionStorage.getItem('staticSuggestions')) {
			try {
				var parsedStaticSuggestions = JSON.parse(sessionStorage.getItem('staticSuggestions')).staticSuggestion;
				var staticBrandsFromStorage = parsedStaticSuggestions.brands;
				if (staticBrandsFromStorage) {
					for (var iBrand = 0; iBrand < staticBrandsFromStorage.length; iBrand++) {
						var brandToTest = staticBrandsFromStorage[iBrand];
						var brandCompareString = brandToTest.displayName.toLowerCase();
						if (brandCompareString != 'chanel') {
							var splitBrandCompareString = brandCompareString.split(' ');
							var splitSearchTerm = this.state.searchTerm.split(' ');
							var goodSuggestion = true;
							for (var iTerm = 0; iTerm < splitSearchTerm.length; iTerm++) {
								var thisTerm = splitSearchTerm[iTerm];
								var foundTerm = false;
								for (var iBrandCompare = 0; iBrandCompare < splitBrandCompareString.length; iBrandCompare++) {
									var brandTerm = splitBrandCompareString[iBrandCompare];
									if (brandTerm.indexOf(thisTerm) == 0) {
										foundTerm = true;
										break;
									}
								}
								if (!foundTerm) {
									goodSuggestion = false;
									break;
								}
							}
							if (goodSuggestion) {
								staticSuggestionsView.staticBrands.unshift(brandToTest);
								if (staticSuggestionsView.staticBrands.length >= 6) break;
							}
						}
					}
				}
				var staticStoresFromStorage = parsedStaticSuggestions.stores;
				if (staticStoresFromStorage) {
					for (var iStore = 0; iStore < staticStoresFromStorage.length; iStore++) {
						var thisStore = staticStoresFromStorage[iStore];
						if (thisStore.entryName.indexOf(this.state.searchTerm) > -1) {
							staticSuggestionsView.staticStores.push(thisStore);
							if (staticSuggestionsView.staticStores.length >= 4) break;
						}
					}
				}
			} catch (e) { }
		}
		return staticSuggestionsView;
	}
	wrapSearchTermInSuggestion (suggestion) {
		var formattedSuggestion = suggestion;
		var searchTermSplit = this.state.searchTerm.split(' ');
		for (var i = 0; i < searchTermSplit.length; i++) {
			var searchWord = searchTermSplit[i];
			var suggestionSplit = suggestion.split(' ');
			for (var j = 0; j < suggestionSplit.length; j++) {
				var termWord = suggestionSplit[j];
				if (termWord.toLowerCase().indexOf(searchWord.toLowerCase()) == 0) {
					var startIndex = formattedSuggestion.toLowerCase().indexOf(termWord.toLowerCase());
					var endIndex = startIndex + searchWord.length;
					formattedSuggestion = formattedSuggestion.substring(0, startIndex) + '~' + formattedSuggestion.substring(startIndex, endIndex) + '~~' + formattedSuggestion.substring(endIndex, formattedSuggestion.length);
				}
			};
		};
		return formattedSuggestion.split('~~').join('</span>').split('~').join('<span class="hilite-term">');
	}
	clearSearchTerm (event) {
		if (this.props.passedAppFunctions.siteFormat != 'mobile') {
			var searchButtonToHide = document.querySelector('.headerSearchButton');
			if (document.querySelectorAll('.headerClearSearch').length > 0) {
				searchButtonToHide.style.display = 'block';
			}
		}
		this.setState({
			searchTerm: '',
		});
		this.state.suggestionView.entry = this.emptySearchTermSuggests();
	}
	handleSubmit (event) {
		if (this.state.searchTerm.trim().length == 0 && this.state.highlightedSuggestion.number == -1) event.preventDefault();
		var searchTerm = this.state.searchTerm.trim();
		if (searchTerm.length <= 0) {
			if (!this.state.emptyTermSubmit) {
				this.setState({
					emptyTermSubmit: true
				});
			}
		}
		else if (searchTerm.length > 0) {
			if (this.state.emptyTermSubmit) {
				this.setState({
					emptyTermSubmit: false
				});
			}
		}
		try {
			if (this.state.highlightedSuggestion.number > -1) {
				searchTerm = this.state.suggestionView.entry[this.state.highlightedSuggestion.number].cleanTerm;
			}
			if (!this.state.alreadyFiredCmSearch && this.state.searchTerm && this.state.searchTerm.length > 0) {
				this.state.alreadyFiredCmSearch = true;
				manualLinkClick(`/search-term/${this.state.searchTerm}?cm_sp=searchbox-_-term-_-${this.state.searchTerm}`);
			}
		} catch (e) { }

		if (window.Modernizr && Modernizr.sessionstorage && searchTerm != "" && searchTerm != undefined) {
			if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url !== undefined && this.state.highlightedSuggestion.url.indexOf('/brand/') > 0 && this.state.highlightedSuggestion.url.indexOf('/stores/') > 0) {
				localStorage.setItem('lastSearchTerm', searchTerm);
				sessionStorage.setItem('searchTerm', searchTerm);
			} else if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url === undefined) {
				localStorage.setItem('lastSearchTerm', searchTerm);
				sessionStorage.setItem('searchTerm', searchTerm);
			}
		}
		this.captureEntryForHistory();
	}
	trackClick (event) {
		this.captureEntryForHistory(event);
		try {
			var searchTerm = event.currentTarget.getAttribute('data-cleanterm');
			var targetHref = event.currentTarget.getAttribute('href');
			var targetDataTrack = event.currentTarget.getAttribute('data-cmtracking');
			if (targetHref.indexOf('?') > -1) {
				targetHref += '&';
			} else {
				targetHref += '?';
			}
			targetHref += 'cm_sp=';
			targetHref += targetDataTrack;
			manualLinkClick(targetHref);
		} catch (e) { }
		if (window.Modernizr && Modernizr.sessionstorage && searchTerm != "" && searchTerm != undefined) {
			if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url !== undefined && this.state.highlightedSuggestion.url.indexOf('/brand/') > 0 && this.state.highlightedSuggestion.url.indexOf('/stores/') > 0) {
				localStorage.setItem('lastSearchTerm', searchTerm);
				sessionStorage.setItem('searchTerm', searchTerm);
			} else if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url === undefined) {
				localStorage.setItem('lastSearchTerm', searchTerm);
				sessionStorage.setItem('searchTerm', searchTerm);
			}
		}
	}
	captureEntryForHistory (event) {
		// we need to store off the entered or clicked entry for history use later
		//  this.state.highlightedSuggestion.url || this.state.registryUrl || `/search-term/${cleanTermForURL(this.state.searchTerm)}`
		//  [{displayName:"asd",responseURL:"/c/asd",cmForLinkClick:'asd-_-asd-_-asd'}]
		try {
			if (window.Modernizr && Modernizr.localstorage && this.state.searchTerm != "" && this.state.searchTerm != undefined) {
				var searchHist = [];
				if (localStorage.getItem('searchHist')) {
					searchHist = JSON.parse(localStorage.getItem('searchHist'));
				}
				// customer already has a history.  Let's update it. 
				// if less than 5 entries, just push to front.
				// if 5 entries, push to front and trim to 5
				// if customer has no history create it as a single entry in array
				var newSearchHistObject = {};
				if (event) {

					newSearchHistObject.responseURL = event.currentTarget.getAttribute('href');
					newSearchHistObject.displayName = event.currentTarget.innerText.split('View all results for ').join('').split('"').join('');
					newSearchHistObject.cmForLinkClick = event.currentTarget.getAttribute('data-cmtracking');
				} else {
					newSearchHistObject.responseURL = this.state.highlightedSuggestion.url || this.state.registryUrl || `/search-term/${cleanTermForURL(this.state.searchTerm)}`;
					newSearchHistObject.displayName = this.state.highlightedSuggestion.url ? this.state.suggestionView.entry[this.state.highlightedSuggestion.number].displayName.split('<span class="hilite-term">').join('').split('</span>').join('') : this.state.searchTerm;
					if (newSearchHistObject.displayName.indexOf('View all') > -1) {
						newSearchHistObject.displayName = this.state.searchTerm;
					}
					newSearchHistObject.cmForLinkClick = this.state.highlightedSuggestion.url ? this.state.suggestionView.entry[this.state.highlightedSuggestion.number].cmForLinkClick : `searchbox-_-term-_-${this.state.searchTerm}`;
				}
				//we have new object now.  Let's make sure it isnt already in here.  
				// If it is we will remove it so it pops back to front.
				var searchHistHolder = [];
				for (var i = 0; i < searchHist.length; i++) {
					var thisSearchHistObject = searchHist[i];
					if (thisSearchHistObject.responseURL != newSearchHistObject.responseURL) {
						searchHistHolder.push(thisSearchHistObject);
					}
				}
				searchHist = searchHistHolder;
				searchHist.unshift(newSearchHistObject);
				searchHist = searchHist.slice(0, 5);




				if (window.Modernizr && Modernizr.sessionstorage && this.state.searchTerm != "" && this.state.searchTerm != undefined) {
					if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url !== undefined && this.state.highlightedSuggestion.url.indexOf('/brand/') > 0 && this.state.highlightedSuggestion.url.indexOf('/stores/') > 0) {
						localStorage.setItem('searchHist', JSON.stringify(searchHist));
					} else if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url === undefined) {
						localStorage.setItem('searchHist', JSON.stringify(searchHist));
					}
				}







				if (window.Modernizr&& Modernizr.sessionstorage && newSearchHistObject.responseURL.indexOf('search-term') > -1) {
					if (event) {
						this.handleChange({ target: { value: newSearchHistObject.displayName } });
					}

					if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url !== undefined && this.state.highlightedSuggestion.url.indexOf('/brand/') > 0 && this.state.highlightedSuggestion.url.indexOf('/stores/') > 0) {
						localStorage.setItem('lastSearchTerm', newSearchHistObject.displayName);
						sessionStorage.setItem('searchTerm', newSearchHistObject.displayName);
					} else if (this.state.suggestionView.entry[0] !== undefined && this.state.highlightedSuggestion.url === undefined) {
						localStorage.setItem('lastSearchTerm', newSearchHistObject.displayName);
						sessionStorage.setItem('searchTerm', newSearchHistObject.displayName);
					}

				}
				if (event) {
					this.state.suggestionView.entry = this.emptySearchTermSuggests();
				}
			}
		} catch (e) { console.log(e); }
	}

	clearSearchHist (event) {
		event.stopPropagation();
		localStorage.removeItem('searchHist');
		this.state.suggestionView.entry = [];
		this.state.searchHistoryActive = false;
		this.state.ariaExpanded = false;
		this.handleLinkUp();
	}

	emptySearchTermSuggests () {
		var emptySearchTermSuggests = [];
		if (typeof window != 'undefined') {
			try {
				if (window.Modernizr && Modernizr.localstorage && localStorage.getItem('searchHist')) {
					emptySearchTermSuggests = JSON.parse(localStorage.getItem('searchHist'));
					this.state.searchHistoryActive = true;
				}
			} catch (e) {
				console.log(e);
			}
		}
		return emptySearchTermSuggests;
	}
	injectSearchTermInToBox (term) {
		this.handleChange({ target: { value: term } });
	}
	componentDidMount () {
		if (window.Modernizr && Modernizr.sessionstorage && sessionStorage.getItem('searchTerm')) {
			this.setState({ searchTerm: sessionStorage.getItem('searchTerm') });
			window.isRealSearch = sessionStorage.getItem('searchTerm');
			sessionStorage.removeItem('searchTerm');
		}
		window.injectSearchTermInToBox = this.injectSearchTermInToBox;
	}
};

export default Search;
