import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TweenFunctions from 'tween-functions';
// import detectPassiveEvents from 'detect-passive-events';
import { supportsPassiveEvents } from 'detect-passive-events';
import objectAssign from 'object-assign';
export default class ScrollButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = { show: false };
		this.data = {
			startValue: 0,
			currentTime: 0,
			startTime: null,
			rafId: null,
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.scrollStep = this.scrollStep.bind(this);
		this.stopScrolling = this.stopScrolling.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.show !== this.state.show;
	}

	componentDidMount() {
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('wheel', this.stopScrolling, supportsPassiveEvents ? { passive: true } : false);
		window.addEventListener('touchstart', this.stopScrolling, supportsPassiveEvents ? { passive: true } : false);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('wheel', this.stopScrolling, false);
		window.removeEventListener('touchstart', this.stopScrolling, false);
	}

	handleScroll() {
		if (window.pageYOffset > this.props.showUnder) {
			if (!this.state.show) {
				this.setState({ show: true });
			}
		} else {
			if (this.state.show) {
				this.setState({ show: false });
			}
		}
	}

	handleClick(e) {
		e.preventDefault();
		e.stopPropagation();

		this.stopScrolling();
		this.data.startValue = window.pageYOffset;
		this.data.currentTime = 0;
		this.data.startTime = null;
		this.data.rafId = window.requestAnimationFrame(this.scrollStep);

		if (document.getElementById('skipNavLink')) {
			document.getElementById('skipNavLink').classList.add('skipNavFocus');
			setTimeout(() => {
				document.getElementById('skipNavLink').focus();
			}, 500);

			document.getElementById('skipNavLink').addEventListener('focusout', (event) => {
				document.getElementById('skipNavLink').classList.remove('skipNavFocus');
			});
		}
	}

	scrollStep(timestamp) {
		if (!this.data.startTime) {
			this.data.startTime = timestamp;
		}

		this.data.currentTime = timestamp - this.data.startTime;

		let position = TweenFunctions[this.props.easing](this.data.currentTime, this.data.startValue, this.props.topPosition, this.props.duration);

		if (window.pageYOffset <= this.props.topPosition) {
			this.stopScrolling();
		} else {
			window.scrollTo(window.pageYOffset, position);
			this.data.rafId = window.requestAnimationFrame(this.scrollStep);
		}
	}

	stopScrolling() {
		window.cancelAnimationFrame(this.data.rafId);
	}

	render() {
		let propStyle = this.props.style;
		let element = (
			<div style={propStyle} onClick={this.handleClick}>
				{this.props.children}
			</div>
		);

		let style = objectAssign({}, ScrollButton.defaultProps.style);
		style = objectAssign(style, propStyle);
		style.opacity = this.state.show ? 1 : 0;
		style.visibility = this.state.show ? 'visible' : 'hidden';
		style.transitionProperty = 'opacity, visibility';
		return React.cloneElement(element, { style: style });
	}
}

ScrollButton.defaultProps = {
	duration: 250,
	easing: 'easeOutCubic',
	style: {
		position: 'fixed',
		bottom: 120,
		right: 15,
		cursor: 'pointer',
		transitionDuration: '0.2s',
		transitionTimingFunction: 'linear',
		transitionDelay: '0s',
		zIndex: 1000,
	},
	topPosition: 0,
};

ScrollButton.propTypes = {
	topPosition: PropTypes.number,
	showUnder: PropTypes.number.isRequired,
	easing: PropTypes.oneOf([
		'linear',
		'easeInQuad',
		'easeOutQuad',
		'easeInOutQuad',
		'easeInCubic',
		'easeOutCubic',
		'easeInOutCubic',
		'easeInQuart',
		'easeOutQuart',
		'easeInOutQuart',
		'easeInQuint',
		'easeOutQuint',
		'easeInOutQuint',
		'easeInSine',
		'easeOutSine',
		'easeInOutSine',
		'easeInExpo',
		'easeOutExpo',
		'easeInOutExpo',
		'easeInCirc',
		'easeOutCirc',
		'easeInOutCirc',
		'easeInElastic',
		'easeOutElastic',
		'easeInOutElastic',
		'easeInBack',
		'easeOutBack',
		'easeInOutBack',
		'easeInBounce',
		'easeOutBounce',
		'easeInOutBounce',
	]),
	duration: PropTypes.number,
	style: PropTypes.object,
};
