function showTarget(scopeName){
  var ffData = JSON.parse(sessionStorage.getItem('featureFlags'));
  if (ffData !== null){
    var found = false;
    for (var thisFF of ffData){
      if (thisFF.name == scopeName){
        found = true;
        if (thisFF.flag == 'expB'){
          return true;
        }
      }
    }
    if (!found){
      return getABTargetByScope(scopeName);
    } else {
      return false;
    }
  } else {
    return getABTargetByScope(scopeName);
  }
}

function getABTargetByScope(scopeName){
  var status = false;
  
  //setting all rec data into a window scoped variable for adobe sdk migration
  var pageUrl = window.location.href.split('?')[0];
  window.recCallData = window.recCallData ? window.recCallData : [];
  if(recCallData.length > 0){
    if(recCallData[0].url != pageUrl){
      window.recCallData = [];
      recCallData.push({"url" : pageUrl});
    }
  }else{
    recCallData.push({"url" : pageUrl});
  }

  var thisData = {};
  thisData.zone = scopeName;
  thisData.includeWithRecData = false;
  recCallData.push({'adobeTarget' : thisData})

  return status;
}


function rerunAdobe(){
  try {
    if (navigator.userAgent.indexOf('BOIA') == -1){
      window.targetFixes = window.targetFixes || [];
      if (window.targetFixes && window.targetFixes.length > 0){
        var dedupeTargetFixes = [];
        for (var i = 0; i < window.targetFixes.length; i++){
          if (typeof window[window.targetFixes[i]] == 'function' && dedupeTargetFixes.indexOf(window.targetFixes[i]) === -1){
            dedupeTargetFixes.push(window.targetFixes[i]);
            window[window.targetFixes[i]]();
          }
        }
        window.targetFixes = dedupeTargetFixes;
      }
    }
  } catch (e){
    console.log(e);
  }
}


export {
  showTarget,
  rerunAdobe
}