import React, { Component } from 'react';
import { htmlUnescape } from '../../../util/htmlUnescape';
export class SetInnerHTML extends Component {
	render () {
		return (
			<>
				{

					<div
						suppressHydrationWarning={true}
						data-espotname={this.props.espotname ? this.props.espotname : ''}
						data-widgettype={this.props.widgetType ? this.props.widgetType : ''}
						className={`${this.props.className ? this.props.className : ''}`}
						dangerouslySetInnerHTML={{ __html: htmlUnescape(this.props.innerHTML) }}
					/>
				}

			</>
		);
	}
}

export default SetInnerHTML;
