import React, { Component } from 'react';

export class AddressesIcon extends Component {
  render () {
    return (<i className='icon i-addresses'>
      <svg role='img' aria-labelledby='addressesIcon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.98 48.55'>
        <title id='addressesIcon'>addresses</title>
        <g data-name='Layer 2'>
          <g data-name='Layer 1'><path d='M47.55,25.1,40,19.91a.87.87,0,0,0-.49-.15H25V17.56H38.8a1,1,0,0,0,.92-1V6.19a1,1,0,0,0-.92-1H25V1a1,1,0,1,0-2,0V5.19H8.52A.87.87,0,0,0,8,5.34L.43,10.53a1,1,0,0,0,0,1.69L8,17.41a.87.87,0,0,0,.49.15H23v2.19H9.18a1,1,0,0,0-.92,1V31.13a1,1,0,0,0,.92,1H23V47.55a1,1,0,0,0,2,0V32.13H39.46A.87.87,0,0,0,40,32l7.6-5.19a1,1,0,0,0,0-1.69ZM8.79,15.57,2.65,11.38,8.79,7.19H37.88v8.38ZM39.19,30.13H10.1V21.75H39.19l6.13,4.19Z' /></g>
        </g>
      </svg>
    </i>);
  }
};

export class ArrowDownIcon extends Component {
  render () {
    return (<i className='icon i-arrow-down'>
      <svg role='img' aria-labelledby={this.props.downArrowTitleId ? this.props.downArrowTitleId : 'iconArrowDown'} aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.77 25.39'>
        <title id={this.props.downArrowTitleId ? this.props.downArrowTitleId : 'iconArrowDown'}>{this.props.downArrowTitleDesc ? this.props.downArrowTitleDesc : 'arrow-down'}</title>
        <g data-name='Layer 2'>
          <g data-name='Layer 1'><path d='M24.39,25.39a1,1,0,0,1-.71-.29L.29,1.71A1,1,0,0,1,1.71.29L24.39,23,47.07.29a1,1,0,0,1,1.41,1.41L25.09,25.09A1,1,0,0,1,24.39,25.39Z' /></g>
        </g>
      </svg>
    </i>);
  }
};

export class ArrowLeftIcon extends Component {
  render () {
    return (<i className='icon i-arrow-left'>
      <svg role='img' aria-labelledby='arrowLeftIcon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.39 48.77'>
        <title id='arrowLeftIcon'>arrow-left</title>
        <g data-name='Layer 2'>
          <g data-name='Layer 1'><path d='M24.39,48.77a1,1,0,0,1-.71-.29L.29,25.09a1,1,0,0,1,0-1.41L23.68.29a1,1,0,0,1,1.41,1.41L2.41,24.39,25.09,47.07a1,1,0,0,1-.71,1.71Z' /></g>
        </g>
      </svg>
    </i>);
  }
};

export class ArrowRightIcon extends Component {
  render () {
    return (<i className='icon i-arrow-right'>
      <svg role='img' aria-labelledby='arrowRightIcon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.39 48.77'>
        <title id='arrowRightIcon'>arrow-right</title>
        <g data-name='Layer 2'>
          <g data-name='Layer 1'>
            <path d='M1,48.77a1,1,0,0,1-.71-1.71L23,24.39.29,1.71A1,1,0,0,1,1.71.29L25.09,23.68a1,1,0,0,1,0,1.41L1.71,48.48A1,1,0,0,1,1,48.77Z'></path>
          </g>
        </g>
      </svg>
    </i>);
  }
};

export class ArrowUpIcon extends Component {
  render () {
    return (<i className='icon i-arrow-up'>
      <svg role='img' aria-labelledby='arrowUpIcon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.77 25.39'>
        <title id='arrowUpIcon'>arrow-up</title>
        <g data-name='Layer 2'>
          <g data-name='Layer 1'><path d='M47.77,25.39a1,1,0,0,1-.71-.29L24.39,2.41,1.71,25.09A1,1,0,0,1,.29,23.68L23.68.29a1,1,0,0,1,1.41,0L48.48,23.68a1,1,0,0,1-.71,1.71Z' /></g>
        </g>
      </svg>
    </i>);
  }
};

export class CalendarIcon extends Component {
  render () {
    return (<i className='icon i-calendar'>
      <svg role='img' aria-labelledby='calendarIcon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50.03 49.88'>
        <title id='calendarIcon'>calendar</title>
        <g data-name='Layer 2'>
          <g data-name='Layer 1'><rect x='28.86' y='30.79' width='15.17' height='13.03' /><path d='M44.78,4.81H40.19V0H29.75V4.81H18.95V0H8.51V4.81H5.25A5.22,5.22,0,0,0,1.7,6.22h0A5.22,5.22,0,0,0,0,10.07V44.63a5.26,5.26,0,0,0,5.25,5.25H44.78A5.26,5.26,0,0,0,50,44.63V10.07A5.26,5.26,0,0,0,44.78,4.81Zm0,43H5.25a3.19,3.19,0,0,1-3.18-3.18V13.1H48V44.63A3.19,3.19,0,0,1,44.78,47.81Z' /></g>
        </g>
      </svg>
    </i>);
  }
};

export class CloseXIcon extends Component {
  render () {
    return (<i className='icon i-close'>
      <svg role='img' aria-labelledby={this.props.closeIconTitleId ? this.props.closeIconTitleId : 'closeIcon'} xmlns='http://www.w3.org/2000/svg' height="30" width="30" viewBox='0 0 48.77 48.77'>
        <title id={this.props.closeIconTitleId ? this.props.closeIconTitleId : 'closeIcon'}>
          {this.props.closeIconTitleDesc ? this.props.closeIconTitleDesc : 'Close'}</title>
        <g data-name='Layer 2'>
          <g data-name='Layer 1'>
            <path d='M25.8,24.39,48.48,1.71A1,1,0,0,0,47.07.29L24.39,23,1.71.29A1,1,0,0,0,.29,1.71L23,24.39.29,47.07a1,1,0,1,0,1.41,1.41L24.39,25.8,47.07,48.48a1,1,0,0,0,1.41-1.41Z' /></g>
        </g>
      </svg>
    </i>);
  }
};

export const SearchIcon = React.forwardRef((props, ref) => {
  return (<i className='icon i-search' ref={ref}>
    <svg role='img' aria-labelledby='searchIcon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.92 47.58'>
      <title id='searchIcon'>search</title>
      <g data-name='Layer 2'>
        <g data-name='Layer 1'><path d='M48.6,45.85,32.28,30.5a18.56,18.56,0,1,0-1.4,1.43L47.23,47.31a1,1,0,1,0,1.37-1.46ZM2,18.42A16.42,16.42,0,1,1,18.42,34.83,16.44,16.44,0,0,1,2,18.42Z' /></g>
      </g>
    </svg>
  </i>);
});

export class EmptyShoppingBag extends Component {
  render () {
    return (<i className='icon i-empty-shopping-bag'>
      <svg role='img' aria-labelledby='shoppingBagIcon' xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 48.93 49.75">
        <title id='shoppingBagIcon'>shopping-bag-outline</title>
        <g data-name="Layer 2">
          <g data-name="Layer 1">
            <path d="M44.43,15.41H40.91A16.48,16.48,0,0,0,8,15.41H4.5A4.5,4.5,0,0,0,0,19.91V45.25a4.5,4.5,0,0,0,4.5,4.5H44.43a4.5,4.5,0,0,0,4.5-4.5V19.91A4.5,4.5,0,0,0,44.43,15.41ZM24.46,2A14.51,14.51,0,0,1,38.91,15.41H10A14.51,14.51,0,0,1,24.46,2ZM46.93,45.25a2.5,2.5,0,0,1-2.5,2.5H4.5A2.5,2.5,0,0,1,2,45.25V19.91a2.5,2.5,0,0,1,2.5-2.5H44.43a2.5,2.5,0,0,1,2.5,2.5Z" />
          </g>
        </g>
      </svg>
    </i>);
  }
};

export class ThumbsUp extends Component {
  render () {
	return (
		<i className='icon i-thumbsUp'>
			<svg role='img' aria-labelledby='thumbsUp' stroke='currentColor' fill='none' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
				<path
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='2'
					d='M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5'
				></path>
			</svg>
		</i>);
  }
};

export const ScissorsIcon = (props) => {
  return (
    <i className='icon i-scissors' style={{ width: props.width, height: props.height }}>
      <svg role='img' aria-labelledby='scissorsIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.01 221.68">
        <defs><style>.cls-1{`fill:#231f20;stroke:#231f20;stroke-miterlimit:10;stroke-width:2.5px;`}</style></defs>
        <title id='scissorsIcon'>scissors</title>
        <path className="cls-1" d="M179,111.79a22.1,22.1,0,1,1,19.17-11,21.92,21.92,0,0,1-19.17,11m.07-41.2a19.11,19.11,0,1,0,18.42,14.17,19.11,19.11,0,0,0-18.42-14.17" transform="translate(-6.2 -8.28)" />
        <path className="cls-1" d="M116.33,64.84a22.1,22.1,0,1,1,19.71-12,22.06,22.06,0,0,1-19.71,12m.06-41.19a19.1,19.1,0,1,0,8.67,2.09,19.13,19.13,0,0,0-8.67-2.09" transform="translate(-6.2 -8.28)" />
        <path className="cls-1" d="M179.85,122.34A31.77,31.77,0,0,1,164,118.1l1.49-2.6a29,29,0,0,0,29-50.3c-15.89-9.19-33.55.45-39.68,10.62-3,4.92-3.27,10.41-3.46,13.69a16,16,0,0,1-.26,2.64c-2.29,9.77-10.27,17.59-23.06,22.63-7,2.76-14.23,4.58-19.4,4.87l-.17-3c4.86-.28,11.77-2,18.47-4.67,12-4.73,19.15-11.63,21.24-20.52a17,17,0,0,0,.19-2.13c.2-3.56.53-9.51,3.88-15.07C159,63.07,178.42,52.47,196,62.6a32,32,0,0,1-16.1,59.74" transform="translate(-6.2 -8.28)" />
        <path className="cls-1" d="M7.8,170.86,7.59,169a33,33,0,0,1,.5-9.31c1.25-6.78,4.36-12,9-15l.17-.12,2.68-.81C78,126.08,87.75,123.05,88.38,122.81l.52,1.41h0l-.25-1.49,1.72,1.14c.23,1,0,1.34-1.21,1.82h0c-.54.21-1.26.43-2.22.73l-6.52,2-20.64,6.31c-15.57,4.76-32.72,10-39,11.89l-2.25.68c-3.89,2.65-6.42,7-7.52,12.95a32.28,32.28,0,0,0-.58,6.56l79-26.23.94,2.84Z" transform="translate(-6.2 -8.28)" />
        <path className="cls-1" d="M82.54,228.5,93,63.2A32,32,0,0,1,116.61,9.53h.12A31.79,31.79,0,0,1,139.34,19c14.27,14.37,9,35.9-.15,45.28-4.52,4.65-10.19,6.49-13.58,7.59a18.09,18.09,0,0,0-2,.72c-8.06,4.29-12.92,13-14.44,25.77-.86,7.19-.8,13.5.16,18.24l0,.18-1.1,27.77-2.71,68.12-.06.2c-1.78,5.26-6,9.59-12.25,12.53a32.94,32.94,0,0,1-8.87,2.84Zm12.83-166,.68,0L85.77,224.91A33.33,33.33,0,0,0,92,222.67c5.48-2.57,9.05-6.13,10.61-10.57L106.35,117c-1-5-1-11.6-.17-19,1.62-13.65,7.15-23.35,16-28.06a17.32,17.32,0,0,1,2.5-.92c3.12-1,8.35-2.71,12.35-6.83,8.28-8.52,13.1-28,.17-41.07a28.81,28.81,0,0,0-20.49-8.58h-.11A29,29,0,0,0,96,61.94Z" transform="translate(-6.2 -8.28)" />
        <path className="cls-1" d="M107.31,137.83l-1-2.82c.14,0,14.5-5.26,28.81-10.24,29.86-10.4,30.37-9.87,31.29-8.92l.31,1.71-2.78-1.15,1.39.57-1.24.76a1.28,1.28,0,0,0,1,.6c-3,0-37,12-57.74,19.49" transform="translate(-6.2 -8.28)" />
        <path className="cls-1" d="M100.78,131.22a2.49,2.49,0,1,1-4.31-2.49,2.49,2.49,0,1,1,4.31,2.49" transform="translate(-6.2 -8.28)" />
        <path className="cls-1" d="M98.61,134a4,4,0,1,1,2-7.44,4,4,0,0,1-1,7.31,4.06,4.06,0,0,1-1,.13m0-5a.73.73,0,0,0-.25,0,1,1,0,0,0-.61.46,1,1,0,0,0,.36,1.35,1.05,1.05,0,0,0,.76.1,1,1,0,0,0,.24-1.81,1,1,0,0,0-.5-.14" transform="translate(-6.2 -8.28)" />
      </svg>
    </i>
  );
};

export const GiftBoxIcon = (props) => {
  return (
    <i className='icon i-giftbox' style={{ width: props.width, height: props.height }}>
      <svg role='img' aria-labelledby='GiftBoxIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.42 49.84">
        <title id='GiftBoxIcon'>Gift Box Icon</title>
        <g>
          <g>
            <path d="M48,9.79H39.64a5.33,5.33,0,0,0-1-9.43,5.3,5.3,0,0,0-4.09.1c-2.16,1-8,7.3-9.37,8.82C23.8,7.76,18,1.42,15.81.46a5.33,5.33,0,0,0-5.1,9.33H2.45A2.45,2.45,0,0,0,0,12.24v4.39a2.45,2.45,0,0,0,2.45,2.45H3a3.46,3.46,0,0,0-.53,1.84V46.35a3.5,3.5,0,0,0,3.49,3.49H44.41a3.5,3.5,0,0,0,3.49-3.49V20.92a3.46,3.46,0,0,0-.53-1.84H48a2.45,2.45,0,0,0,2.45-2.45V12.24A2.45,2.45,0,0,0,48,9.79ZM35.36,2.3A3.32,3.32,0,0,1,38,8.39c-1.2.53-6.12.76-10.05.81C30.68,6.32,34.16,2.83,35.36,2.3ZM10.61,4A3.33,3.33,0,0,1,15,2.3c1.2.53,4.68,4,7.36,6.89-3.93,0-8.85-.28-10.05-.81h0A3.33,3.33,0,0,1,10.61,4ZM2,16.63V12.24a.43.43,0,0,1,.43-.43H24.2v5.26H2.45A.43.43,0,0,1,2,16.63ZM4.47,46.35V20.92a1.48,1.48,0,0,1,1.48-1.48H24.2V47.82H5.94A1.48,1.48,0,0,1,4.47,46.35Zm41.42,0a1.48,1.48,0,0,1-1.48,1.48H26.22V19.44H44.41a1.48,1.48,0,0,1,1.48,1.48Zm2.52-29.71a.43.43,0,0,1-.43.43H26.22V11.81H48a.43.43,0,0,1,.43.43Z" />
          </g>
        </g>
      </svg>
    </i>
  );
};

export const ShoppingBagOutline = (props) => {
  return (
    <i className='icon i-shopping-bag-outline' style={{ width: props.width, height: props.height }}>
      <svg role='img' aria-labelledby='ShoppingBagOutline' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.93 49.75">
        <title id='ShoppingBagOutline'>Shopping Bag Outline</title>
        <g>
          <g>
            <path d="M44.43,15.41H40.91A16.48,16.48,0,0,0,8,15.41H4.5A4.5,4.5,0,0,0,0,19.91V45.25a4.5,4.5,0,0,0,4.5,4.5H44.43a4.5,4.5,0,0,0,4.5-4.5V19.91A4.5,4.5,0,0,0,44.43,15.41ZM24.46,2A14.51,14.51,0,0,1,38.91,15.41H10A14.51,14.51,0,0,1,24.46,2ZM46.93,45.25a2.5,2.5,0,0,1-2.5,2.5H4.5A2.5,2.5,0,0,1,2,45.25V19.91a2.5,2.5,0,0,1,2.5-2.5H44.43a2.5,2.5,0,0,1,2.5,2.5Z" />
          </g>
        </g>
      </svg>
    </i>
  );
};

export const FurnitureIcon = (props) => {
  return (
    <i className='icon i-furniture' style={{ width: props.width, height: props.height }}>
      <svg role='img' aria-labelledby='FurnitureIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 365.09 210.48">
        <title id='FurnitureIcon'>Furniture Icon</title>
        <defs><style>.cls-1{`fill:#231f20;stroke:#231f20;stroke-miterlimit:10;stroke-width:2.5px;`}</style></defs>
        <path class="cls-1" d="M369.21,160.63c.09-12.27.22-73.75-5.28-79.28a2.35,2.35,0,0,0-1.7-.76H346.86c.2-5.75,1.06-49.18-17-68A26.62,26.62,0,0,0,310,4H67a26.63,26.63,0,0,0-19.9,8.61c-18,18.85-17.17,62.28-17,68H13.66a2.36,2.36,0,0,0-1.7.75c-5.51,5.54-5.37,67.64-5.29,80h0A17.44,17.44,0,0,0,11,173.32c6.5,7.7,19.24,11.61,37.89,11.61h7.2c-6.7,5.59-19.6,17-19.31,22.09a3,3,0,0,0,1.43,2.44,15.53,15.53,0,0,0,8.31,2.47,11.69,11.69,0,0,0,3.21-.4l.26-.08L84.6,184.93H293.29l34.63,26.52.26.08a11.73,11.73,0,0,0,3.22.4,15.52,15.52,0,0,0,8.3-2.47,3,3,0,0,0,1.43-2.44c.28-5.05-12.61-16.5-19.31-22.09h5.54c18.43,0,31.05-3.89,37.52-11.54a17.19,17.19,0,0,0,4.27-12.76Zm-319.9-146A23.71,23.71,0,0,1,67,7H310a23.71,23.71,0,0,1,17.73,7.68c17.11,17.88,16.28,61.18,16.13,66H326.31a2.71,2.71,0,0,0-2,.88C322.23,83.6,321,91.65,320.27,101H220.36c-9.86,0-17.57,3-22.92,8.8-5.53,6-7.33,13.76-7.88,19.15-.58-5.3-2.4-12.78-7.81-18.7s-13.49-9-23.75-9H56.63c-.72-9.46-2-17.61-4.1-19.76a2.69,2.69,0,0,0-2-.88H33.18c-.15-4.78-1-48.08,16.13-66M319.31,135H192.39c-.09-1.64-.58-14.6,7.27-23.15,4.76-5.18,11.73-7.81,20.7-7.81h99.7c-.77,11.94-.79,25.19-.75,31M56.84,104.23H158c9.37,0,16.61,2.71,21.52,8.06,7.57,8.26,7.36,20.44,7.25,22.7H57.58c0-5.73,0-18.87-.74-30.76M48.59,208.72a11.9,11.9,0,0,1-8.82-1.8c-.77-2.12,9-12.35,21-22H79.66Zm289.51-1.8a11.85,11.85,0,0,1-8.8,1.8l-31.07-23.79h18.86c12,9.64,21.8,19.87,21,22m24.48-35.46c-4,4.77-13.41,10.47-35.22,10.47H48.87c-22,0-31.47-5.7-35.54-10.48a14,14,0,0,1-3.61-10.58H9.67C9.45,125.44,11,87.35,14,83.59H50.43c2.85,2.36,4.36,29.16,4.13,52.88V138H322.33v-1.52c-.23-23.72,1.29-50.52,4.14-52.88h35.46c3,3.74,4.53,41.81,4.29,77h1.43l-1.48.25a14.08,14.08,0,0,1-3.58,10.59" transform="translate(-5.4 -2.7)" />
      </svg>
    </i>
  );
};

export const CurbsideDeliveryIcon = (props) => {
  return (
    <i className='icon i-curbside-delivery' style={{ width: props.width, height: props.height }}>
      <svg role='img' aria-labelledby='CurbsideDeliveryIcon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 262.08 266.57">
        <title id='CurbsideDeliveryIcon'>Curbside Delivery Icon</title>
        <defs><style>.cls-1{`fill:#231f20;stroke:#231f20;stroke-miterlimit:10;stroke-width:2.5px;`}</style></defs>
        <path class="cls-1" d="M156.21,248.93c-9.25,0-15.52-.2-16.46-.23l.1-3c.91,0,91.7,2.92,115-14.56,22.84-17.1-.24-72-.48-72.52l-.12-.28V81.83c2.1-16.45-2.09-30.19-12.44-40.83C207.6,5.75,116.61,14.26,115.7,14.35l-.3-3C119.2,11,208.76,2.63,244,38.9c11,11.32,15.47,25.85,13.28,43.21v75.62c2,4.88,23.46,57.79-.6,75.81-18.14,13.58-72.73,15.39-100.48,15.39" transform="translate(-7.19 -9.03)" /><path class="cls-1" d="M125.44,248.93c-29,0-86.16-1.81-105.13-15.39-25.18-18-2.79-71-.63-75.91V81.86c0-28,17.06-49.18,49.32-61.12,24.12-8.92,48.58-9.6,48.82-9.6l.07,3c-.24,0-24.25.67-47.91,9.44C38.6,35.21,22.68,54.82,22.68,81.86v76.41l-.13.29c-.24.56-24.38,55.44-.49,72.54,24.46,17.52,119.56,14.63,120.52,14.6l.09,3c-1,0-7.55.23-17.23.23" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M221.6,212.33H49.84l-.45-.5c-.35-.39-8.48-9.73-11.18-33.37-2.46-21.56-.64-59.76,22-119.35l.37-1H222.75l.3,1.1c1.32,4.86,32.14,119.13,11,146.85a15,15,0,0,1-12.42,6.24m-170.37-3H221.6a12,12,0,0,0,10-5.07c19.22-25.15-8-131.08-11.18-143.12H62.65C23,166,47.72,204.62,51.23,209.33" transform="translate(-7.19 -9.03)" /><path class="cls-1" d="M38.64,274.27c-9.94,0-17.29-8.79-17.6-9.16l-.35-.42.14-32.57,3,0-.13,31.45c1.48,1.62,7.53,7.68,14.94,7.68h.1c4.78,0,9.32-2.61,13.5-7.65v-21h3v22.06l-.33.41c-4.87,6.06-10.3,9.15-16.17,9.18h-.1" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M237.78,274.35h-.1c-5.86,0-11.3-3.12-16.17-9.18l-.33-.41V242.7h3v21c4.18,5,8.72,7.62,13.5,7.65h.1c7.41,0,13.46-6.07,14.94-7.68l-.13-31.45,3,0,.14,32.57-.35.42c-.31.37-7.66,9.16-17.6,9.16" transform="translate(-7.19 -9.03)" />
        <rect class="cls-1" x="137.89" y="-28.32" width="3" height="128.03" transform="matrix(0.01, -1, 1, 0.01, 95.19, 165.7)" />
        <path class="cls-1" d="M62.68,232.25H39.23V219.4H62.68Zm-20.45-3H59.68V222.4H42.23Z" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M234.41,232.25H211V219.4h23.45Zm-20.45-3h17.45V222.4H214Z" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M45.1,119.92l-2.72-1.25c.29-.65,7.39-15.87,18.11-15.87h78.9v3H60.49c-8.8,0-15.32,14-15.39,14.12" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M233.16,119.92c-.06-.14-6.55-14.12-15.29-14.12H139.39v-3h78.48c10.66,0,17.72,15.22,18,15.87Z" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M40.35,159.46l-2.77-1.17c.32-.73,7.74-17.93,19-17.93h82.92v3H56.57c-9.27,0-16.16,15.94-16.22,16.1" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M238.1,159.46c-.06-.13-6.92-13.57-16.12-13.57H139.49v-2.53H222c11.19,0,18.58,14.5,18.89,15.12Z" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M194.24,97.6h-.38l-17.29-.12c-2.58.35-4.52,0-5.76-1.19a4,4,0,0,1-1.28-3v-12c0-4.84,4.38-6.84,6.69-7.23l.22,0,16.71-.36c5.18-.56,7.17,2.7,7.51,4.51l0,.27V92.28a3.83,3.83,0,0,1-.75,3.33c-1.51,1.82-4.57,2-5.69,2m-17.87-3.14,17.58.13c1.16,0,3.1-.2,3.67-.9.07-.09.24-.29.1-.9l0-.35V78.54c-.16-.56-.93-2.35-4.26-2h-.13l-16.66.36c-.64.13-4.1,1-4.1,4.25V93.34a.93.93,0,0,0,.34.76c.55.48,1.79.61,3.38.38Z" transform="translate(-7.19 -9.03)" />
        <path class="cls-1" d="M99.28,97.44H98.9l-17.3-.12c-2.58.36-4.52,0-5.76-1.19a4,4,0,0,1-1.28-3V81c0-4.84,4.38-6.84,6.69-7.23l.22,0,16.72-.36c5.17-.56,7.17,2.71,7.5,4.52l0,.27V92.13a3.82,3.82,0,0,1-.75,3.32c-1.5,1.82-4.57,2-5.68,2M81.4,94.31,99,94.44c1.17,0,3.1-.21,3.67-.9.08-.09.25-.3.1-.91l0-.34V78.38c-.16-.56-.93-2.35-4.25-2h-.14l-16.66.36c-.64.13-4.1,1-4.1,4.25V93.18a1,1,0,0,0,.34.76c.56.48,1.79.62,3.38.38Z" transform="translate(-7.19 -9.03)" />
        <rect class="cls-1" x="74.19" y="85.4" width="3" height="8.7" /><rect class="cls-1" x="86.13" y="87.22" width="3" height="7.03" />
        <rect class="cls-1" x="170.33" y="87.07" width="3" height="6.09" /><rect class="cls-1" x="182.33" y="87.98" width="3" height="6.11" />
      </svg>
    </i>
  );
};


export const StrollerIcon = (props) => {
  return (
    <i className='icon i-stroller' style={{ width: props.width, height: props.height }}>
      <svg role='img' aria-labelledby='strollerIcon' viewBox="0 0 512.015 512.015" xmlns="http://www.w3.org/2000/svg">
        <title id='strollerIcon'>Stroller Icon</title>
        <g>
          <path d="m511.52 211.734c.317-1.228.488-2.525.488-3.734 0-116.666-88.643-208-192-208h-24c-6.465 0-12.203 4.142-14.237 10.279l-60.593 182.721h-130.092l-19.331-62.826c-7.46-24.243-30.795-41.174-56.748-41.174-8.284 0-15 6.716-15 15s6.716 15 15 15c12.702 0 24.509 8.41 28.074 19.996 24.838 80.722 25.742 82.284 50.954 152.878 9.212 25.795 38.193 43.126 72.114 43.126h51.701l-14.004 35.006c-43.632-7.358-82.839 26.452-82.839 69.994 0 39.384 31.892 72 71 72 34.373 0 62.655-25.039 69.419-57h53.163c2.814 13.296 9.34 25.589 19.086 35.575 27.894 28.583 72.764 28.591 100.666 0 13.327-13.656 20.667-31.617 20.667-50.575 0-43.549-39.215-77.35-82.839-69.994l-14.005-35.006h51.702c33.885 0 62.839-17.294 72.084-43.042 30.998-83.818 29.259-79.012 29.57-80.224zm-204.683-181.734h13.171c84.731 0 154.478 71.841 161.431 163h-228.654zm-114.83 452c-22.224 0-41-19.234-41-42 0-22.607 18.393-41 41-41s41 18.393 41 41c0 22.766-18.775 42-41 42zm233-42c0 22.766-18.775 42-41 42s-41-19.234-41-42c0-22.607 18.393-41 41-41s41 18.393 41 41zm-80.689-58.843c-14.906 10.086-25.78 25.686-29.701 43.843h-53.218c-3.922-18.157-14.796-33.757-29.701-43.843l18.464-46.157h75.69zm109.468-99.531c-5.886 15.892-26.715 23.374-43.92 23.374-7.82 0-235.001 0-243.716 0-20.611 0-39.059-9.764-43.863-23.216l-20.994-58.784h374.175z" />
        </g>
      </svg>
    </i>
  );
};

export const GiftBoxIconSolid = (props) => {
  return (
    <i className='icon' style={{ width: props.width, height: props.height }}>
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z"></path></svg>
    </i>
  );
};

export const StrollerIconSolid = (props) => {
  return (
    <i className='icon' style={{ width: props.width, height: props.height }}>
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M144.8 17c-11.3-17.8-37.2-22.8-54-9.4C35.3 51.9 0 118 0 192h256L144.8 17zM496 96h-48c-35.3 0-64 28.7-64 64v64H0c0 50.6 23 96.4 60.3 130.7C25.7 363.6 0 394.7 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-8.9-1.8-17.2-4.4-25.2 21.6 5.9 44.6 9.2 68.4 9.2s46.9-3.3 68.4-9.2c-2.7 8-4.4 16.3-4.4 25.2 0 44.2 35.8 80 80 80s80-35.8 80-80c0-37.3-25.7-68.4-60.3-77.3C425 320.4 448 274.6 448 224v-64h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM80 464c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm320-32c0 17.6-14.4 32-32 32s-32-14.4-32-32 14.4-32 32-32 32 14.4 32 32z"></path></svg>
    </i>
  );
};

export const SalonIconSolid = (props) => {
  return (
    <i className='icon' style={{ width: props.width, height: props.height }}>
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.06 256L444.48 89.57c4.69-4.69 4.69-12.29 0-16.97-32.8-32.8-85.99-32.8-118.79 0L210.18 188.12l-24.86-24.86c4.31-10.92 6.68-22.81 6.68-35.26 0-53.02-42.98-96-96-96S0 74.98 0 128s42.98 96 96 96c4.54 0 8.99-.32 13.36-.93L142.29 256l-32.93 32.93c-4.37-.61-8.83-.93-13.36-.93-53.02 0-96 42.98-96 96s42.98 96 96 96 96-42.98 96-96c0-12.45-2.37-24.34-6.68-35.26l24.86-24.86L325.69 439.4c32.8 32.8 85.99 32.8 118.79 0 4.69-4.68 4.69-12.28 0-16.97L278.06 256zM96 160c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm0 256c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z"></path></svg>
    </i>
  );
};

export const ShoppingBagSolid = (props) => {
  return (
    <i className='icon' style={{ width: props.width, height: props.height }}>
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M352 160v-32C352 57.42 294.579 0 224 0 153.42 0 96 57.42 96 128v32H0v272c0 44.183 35.817 80 80 80h288c44.183 0 80-35.817 80-80V160h-96zm-192-32c0-35.29 28.71-64 64-64s64 28.71 64 64v32H160v-32zm160 120c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm-192 0c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z"></path></svg>
    </i>
  );
};

export const FurnitureIconSolid = (props) => {
  return (
    <i className='icon' style={{ width: props.width, height: props.height }}>
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M160 224v64h320v-64c0-35.3 28.7-64 64-64h32c0-53-43-96-96-96H160c-53 0-96 43-96 96h32c35.3 0 64 28.7 64 64zm416-32h-32c-17.7 0-32 14.3-32 32v96H128v-96c0-17.7-14.3-32-32-32H64c-35.3 0-64 28.7-64 64 0 23.6 13 44 32 55.1V432c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-16h384v16c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16V311.1c19-11.1 32-31.5 32-55.1 0-35.3-28.7-64-64-64z"></path></svg>
    </i>
  );
};

export const CurbsideIconSolid = (props) => {
  return (
    <i className='icon' style={{ width: props.width, height: props.height }}>
      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"></path></svg>
    </i>
  );
};



